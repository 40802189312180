import axios from 'axios'
import {Auth} from '@/default/services/Auth'
// import store from '@/default/store'

export class Interceptors {
  static Axios (config) {
    var r = axios.create({
      baseURL: config.url
    })
    r.interceptors.request.use(request => {
      const auth = Auth.get()
      if (auth) {
        request.headers.Token = auth.token
        request.headers.Hash = auth.hash
      }
      return request
    }, error => {
      return Promise.reject(error)
    })
    r.interceptors.response.use(response => {
      return response
    }, error => {
      console.log(error)
      if (error.response && error.response.status === 401) {
        Auth.logout()
      }
      return Promise.reject(error)
    })
    return r
  }
}
