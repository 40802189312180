<template>
  <h1>Por favor aguarde...</h1>
</template>

<script>
export default {
  methods: {
    checkRequestData: function () {
      const hash = this.$route.params.hash
      if (hash) {
        this.$Auth.saveAuthentication({
          data: {
            token: hash
          }
        })
        this.$router.push({name: 'home'})
        window.location.reload()
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro, tente novamente ou contact o suporte!",
        });
      }
    },
    clearUser: function () {
      localStorage.clear()
    }
  },
  mounted: function () {
    this.clearUser()
    this.checkRequestData()
  }
}
</script>

<style>

</style>