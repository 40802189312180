<template>
  <v-app :style="loginStyle">
    <v-container fill-height fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="8" lg="8" class="register">
          <div class="register__content">
            <v-card class="elevation-20">
              <v-container>
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="8" lg="8" class="register">
                    <h3 class="register-item__title">PARA COMEÇAR, INSIRA O CÓDIGO DA FORMATURA</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" lg="8" class="register">
                    <v-text-field v-model="form.graduation_code" :loading="loading" @keyup.enter="search" :disabled="form.graduation_id != null && form.graduation_id != undefined" outlined block label="Digite o código da Formatura"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" class="register">
                    <v-btn v-if="!form.graduation_id" block x-large :loading="loading" :disabled="!form.graduation_code" color="primary" @click="search()">
                      <v-icon>search</v-icon>
                      <span>Buscar</span>
                    </v-btn>
                    <v-btn v-else block x-large :loading="loading" color="primary" link :to="{name: 'register', params: {stage: 1}}">
                      <!-- <v-icon>search</v-icon> -->
                      <span>Próximo</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterRequired',
    data: () => ({
      graduation: null
    }),
    computed: {
      config () {
        return this.$store.state.system.login
      },
      loginStyle () {
        var backgroundImage = this.config.background.url || 'assets/background/login-background-falback.jpg'
        var backgroundGradient = this.config.background.gradient || '0, 0, 0, 0.5'
        return {
          'background': "linear-gradient(0deg, rgba(" + backgroundGradient + "), rgba(" + backgroundGradient + ")), url('" + backgroundImage + "')",
          'background-position': 'center',
          'background-size': 'cover'
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.graduation 
        },
        set (value) {
          this.$store.commit('register/formGraduation', value)
        }
      }
    },
    methods: {
      search: function () {
        this.loading = true
        this.$Auth.register('required/graduation', this.form,
        response => {
          this.checkRequiredResponse(response)
          this.loading = false
        })
      },
      checkRequiredResponse: function (response) {
        if (response && response.data && response.data.next) {
          this.showMessageSuccess()
          this.setDataInStorage(response.data)
          this.setDataInStore(response.data)
          this.goToNextStage()
        } else {
          this.showMessageError()
        }
      },
      showMessageSuccess: function () {
        this.$swal.fire({
          icon: 'success',
          title: 'Formatura encontrada com sucesso!',
          text: 'Informe agora seus dados pessoais.',
          confirmButtonText: 'Ok, prosseguir'
        })
      },
      showMessageError: function () {
        this.$swal.fire({
          icon: 'error',
          title: 'Formatura não encontrada!',
          text: 'Tente novamente ou contacte a empresa.'
        })
      },
      setDataInStorage: function (data) {
        this.$Storage.set('register-0', data)
      },
      goToNextStage: function () {
        this.$router.push({name: 'register', params: {stage: 1}})
      },
      setDataInStore: function (data) {
        this.form = data
      },
      setStorageInStore: function () {
        var storage = this.$Storage.get('register-0')
        if (storage) {
          this.setDataInStore(storage)
        }
      }
    },
    mounted: function () {
      this.setStorageInStore()
    },
    mixins: [RegisterMixin]
  }
</script>
<style scoped>

</style>
