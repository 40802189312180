import couponDialog from '@/default/store/register/stages/business/coupon-dialog'

export default {
  namespaced: true,
  state: {
    id: 1
  },
  modules: {
    couponDialog
  }
}
