import Vue from 'vue'
import Router from 'vue-router'
import {Auth} from '@/default/services/Auth'
import Main from '@/default/pages/Main/Main'
import Login from '@/default/pages/Login/Login'
import Password from '@/default/pages/Login/Password'
import A8818fa5b from '@/default/pages/Login/dd18fa5b-3818-4177-a2cf-151b90cd58c8'
import Register from '@/default/pages/Register/Register'
import RegisterRequired from '@/default/pages/Register/RegisterRequired'
import Routers from '@/route/route'
import system from '@/default/store/system/system'

Vue.use(Router)

var routes = [
  {
    path: '*',
    redirect: '/main/home'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      title: 'Login'
    },
    beforeEnter: (to, from, next) => {
      if (Auth.check()) {
        next({ name: 'home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/dd18fa5b-3818-4177-a2cf-151b90cd58c8/:hash',
    name: 'dd18fa5b-3818-4177-a2cf-151b90cd58c8',
    component: A8818fa5b
  },
  {
    path: '/register/:stage',
    name: 'register',
    component: Register,
    meta: {
      auth: false,
      title: 'Cadastro'
    },
    beforeEnter: (to, from, next) => {
      if (Auth.check()) {
        next({ name: 'home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/cadastro',
    name: 'register-required',
    component: RegisterRequired,
    meta: {
      auth: false,
      title: 'Cadastro'
    },
    beforeEnter: (to, from, next) => {
      if (Auth.check()) {
        next({ name: 'home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    redirect: '/home',
    meta: {
      auth: true
    },
    children: Routers
  },
  {
    path: '/password',
    name: 'password',
    component: Password,
    meta: {
      auth: true
    }
  }
]

const router = new Router({
  routes: routes
})

var setHTMLTitle = route => {
  if (route.meta.title) {
    document.title = route.meta.title + ' - ' + system.state.company.name
    document.getElementById('favicon').href = system.state.company.favicon
  }
}

router.beforeEach((to, from, next) => {
  setHTMLTitle(to)
  if (to.name !== 'login' && to.meta.auth && !Auth.check()) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
