<template>
  <div v-if="tickets && tickets.data">
    <v-card class="mx-auto text-center" max-width="500">
      <v-responsive :aspect-ratio="16 / 9">
        <v-card-text class="my-10">
          <h2 class="my-3">
            {{ title || 'Nenhum chamado encontrado' }}
          </h2>
          <h3 class="my-10">
            Clique abaixo para criar um novo chamado
          </h3>
          <DialogCreateTicket
            v-if="tickets && tickets.data"
            :refreshTickets="tickets.refresh"
          ></DialogCreateTicket>
        </v-card-text>
      </v-responsive>
    </v-card>
  </div>
</template>
<script>
  import DialogCreateTicket from "@/pages/Tickets/DialogCreateTicket"
  export default {
    name: "TicketsBannerAlert",
    props: ['tickets', 'title'],
    components: {
      DialogCreateTicket
    }
  }
</script>
