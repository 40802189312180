<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn class="white--text" color="green" v-on="on">
        <v-icon>add</v-icon>
        <span>Criar novo Chamado</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Criar novo Chamado/Contato</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-col cols="12">
          <h4>
            Para criar um novo chamado/contato selecione uma categoria<span v-if="config.actions.title">, informe um título </span>
            e descreva sua
            solicitação abaixo.
          </h4>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-select
            v-model="form.category_id"
            :items="categories"
            item-value="id"
            item-text="name"
            :loading="categoriesLoading"
            label="Selecione uma categoria de solicitação"
            outlined
            :rules="[(v) => !!v || 'A categoria é obrigatória']"
          ></v-select>
        </v-col>
        <v-col v-if="config.actions.title" cols="12" class="py-0">
          <v-text-field
            v-model="form.title"
            label="Informe um título/assunto para a solicitação."
            outlined
            :rules="[
              (v) => !!v || 'O título/assunto é obrigatório',
              (v) => (v && v.length <= 100) || 'O título deve ter no máximo 100 caracteres',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-textarea
            v-model="form.description"
            label="Descreva sua solicitação aqui"
            rows="4"
            row-height="20"
            outlined
            auto-grow
            :rules="[(v) => !!v || 'A descrição é obrigatória']"
          ></v-textarea>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          :disabled="
            !form.category_id || 
            !form.description || 
            (config.actions.title && (!form.title || form.title.length > 100))
          "
          :loading="loading"
          color="green"
          class="white--text"
          block
          @click="sendNewTicketToApi()"
        >
          Criar Chamado
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogCreateTicket",
  data: () => ({
    form: {},
    dialog: false,
    loading: false,
    categories: [],
    categoriesLoading: false,
  }),
  computed: {
    config () {
      return this.$store.state.system.ticket
    }
  },
  methods: {
    sendNewTicketToApi: function() {
      this.loading = true;
      this.$Api.post({
        url: "portal/service-desk/ticket",
        data: this.form,
        success: ({ data }) => {
          this.refreshTickets();
          this.resetDialog();
          this.$router.push({
            name: "ticket",
            params: {
              id: data.id,
            }
          })
        },
        error: () => {
          this.loading = false;
        },
        config: {
          message: {
            loading: {
              show: false,
            },
          },
        },
      });
    },
    resetDialog: function() {
      this.loading = false;
      this.dialog = false;
      this.newTicket = {};
    },
    loadCategories: function() {
      this.categoriesLoading = true;
      this.$Api.get({
        url: "portal/service-desk/ticket/category",
        success: (response) => {
          this.categories = response.data;
          this.categoriesLoading = false;
        },
        error: () => {
          this.categoriesLoading = false;
        },
        config: {
          message: {
            loading: {
              show: false,
            },
          },
        },
      });
    },
  },
  mounted: function() {
    this.loadCategories();
  },
  props: ["refreshTickets"],
};
</script>
