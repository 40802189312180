<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="8" lg="8">
        <v-card>
          <v-card-text class="register-finish__content">
            <img src="/assets/register/done.png" class="register-finish__img" alt="Cadastro concluído com sucesso!"/>
            <h2>Parabéns!</h2>
            <br><br>
            <h2>Seu cadastro foi concluído com sucesso.<br>Por favor, faça seu primeiro login clicando no botão abaixo.</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'RegisterFinish',
    data: () => ({}),
    methods: {
      
    }
  }
</script>
<style scoped>
  .register-finish__content {
    text-align: center;
  }
  .register-finish__img {
    width: 100%;
    max-width: 150px;
  }
</style>

