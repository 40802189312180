<template>
  <v-form v-model="isValid" class="register-business">
    <v-container v-if="!businessLoading">
      <v-row v-if="businesses && businesses.length > 0">
        <v-col cols="12">
          <h2>Selecione seu plano de pagamento</h2>
        </v-col>
      </v-row>
      <v-row v-if="businesses && businesses.length > 0" justify="center">
        <v-col cols="12" sm="6" md="4" lg="4" v-for="(business, businessKey) in businesses" :key="businessKey">
          <v-card :dark="isSelected(business)">
            <v-card-title v-if="business && business.category">
              <v-icon x-large :color="business.category.color" v-text="business.category.icon"></v-icon>
              <div class="register-business__item-category" v-text="business.category.name"></div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="business.description" class="register-business__item-description" v-html="business.description"></div>
              <v-divider v-if="business.description"></v-divider>
              <div class="register-business__item-items">
                <v-card-actions class="register-business__item-item" v-for="(item, itemKey) in business.items" :key="itemKey">
                  <strong v-text="item.name"></strong>
                  <v-spacer></v-spacer>
                  <span v-if="item.amount && item.amount > 0">{{item.amount | money}}</span>
                </v-card-actions>
                <v-card-actions v-if="coupon.id" class="register-business__item-coupon">
                  Cupom de desconto
                  <v-spacer></v-spacer>
                  <span>-{{coupon.amount | money}}</span>
                </v-card-actions>
              </div>
              <v-divider></v-divider>
              <v-card-actions class="register-business__item-amount">
                <span>Total:</span>
                <v-spacer></v-spacer>
                <span v-if="coupon.id && !business.coupon_id">{{(business.amount - coupon.amount) | money}}</span>
                <span v-else>{{business.amount | money}}</span>
              </v-card-actions>
              
                <v-dialog v-model="business.financeDialog" scrollable max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="gray" v-bind="attrs" v-on="on" @click="dialogFinances = true" outlined block>Visualizar parcelas</v-btn>
                  </template>
                  <v-card>
                    <v-app-bar dense>
                      <v-icon>paid</v-icon>
                      <v-toolbar-title style="padding-left: 5px;">
                        Parcelas
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="business.financeDialog = false">
                        <v-icon>close</v-icon>
                        <span>Fechar</span>
                      </v-btn>
                    </v-app-bar>
                    <v-divider></v-divider>
                    <v-card-text style="max-height: 500px; padding: 0px;">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center"> Parcela </th>
                              <th class="text-center"> Vencimento </th>
                              <th class="text-center"> Valor </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(finance, financeKey) in business.finances" :key="financeKey" class="text-center">
                              <td>
                                <span style="background: black; color: white; padding: 2px 5px;">{{ finance.parcel }}</span>
                              </td>
                              <td>{{ finance.date_validity }}</td>
                              <td>
                                <strong style="color: green;">{{ finance.amount | money }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>

              <v-card-actions v-if="isSelected(business)" class="text-center">
                <v-btn v-if="!coupon.id" color="gray" outlined block @click="dialogCouponSetActive(true)">Tenho um Cupom</v-btn>
                <div v-else class="text-center">Cupom "{{coupon.slug}}" aplicado</div>
              </v-card-actions>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="green" block outlined :disabled="isSelected(business)" @click="selectBusiness(business)">
                <v-icon>done</v-icon>
                <span v-if="isSelected(business)">Selecionado</span>
                <span v-else>Selecionar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <div class="loader-api__loading-message">
            <h2>Nenhum plano disponível, por favor entre em contato conosco!</h2>
          </div>
        </v-col>
      </v-row>
      <input v-model="form.person_id" type="hidden">
      <input v-model="form.graduation_id" type="hidden">
      <v-text-field v-model="form.business_id" style="display: none;" required :rules="[value => !!value || 'Campo obrigatório.']"></v-text-field>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <div class="loader-api__loading-progress">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
          </div>
          <div class="loader-api__loading-message">
            <h3>Buscando informações, por favor aguarde...</h3>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <CouponDialog></CouponDialog>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  import CouponDialog from '@/default/pages/Register/Stages/Business/CouponDialog'
  export default {
    name: 'RegisterBusiness',
    data: () => ({
      stageName: 'business',
      businesses: [],
      businessLoading: false,
      dialogCoupon: false,
      couponLoading: false,
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.business 
        },
        set (value) {
          this.$store.commit('register/formBusiness', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      person () {
        return this.$store.state.register.form.person 
      },
      graduation () {
        return this.$store.state.register.form.graduation 
      },
      terms () {
        return this.$store.state.register.form.terms 
      },
      coupon () {
        return this.$store.state.register.business.couponDialog.coupon
      }
    },
    methods: {
      dialogCouponSetActive: function (active) {
        this.$store.dispatch('register/business/couponDialog/setActive', active)
      },
      getBusinesses: function () {
        this.businessLoading = true
        if (this.form.id) {
          this.businesses = []
          this.businesses.push(this.form)
          this.businessLoading = false
        } else {
          this.$Auth.register('business/list/' + this.form.graduation_id, {}, 
          response => {
            if (response && response.data) {
              this.businesses = response.data
              this.businessLoading = false
            }
          })
        }
      },
      selectBusiness: function (business) {
        this.form.business_id = business.id
        this.$forceUpdate()
      },
      setDataToStage: function () {
        var graduation = this.$Storage.get('register-0')
        var terms = this.$Storage.get('register-1')
        var person = this.$Storage.get('register-2')
        this.form.person_id = person.id
        this.form.graduation_id = graduation.graduation_id
        this.form.terms_id = terms.id
      },
      isSelected: function (business) {
        return this.form.business_id && this.form.business_id === business.id
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(3)
      this.setDataToForm(9, 'business')
      this.setDataToStage()
      this.getBusinesses()
    },
    props: ['valid'],
    mixins: [RegisterMixin],
    components: {
      CouponDialog
    }
  }
</script>
<style scoped>
  .register-business h2 {
    padding-bottom: 10px;
  }
  .register-business__col {
    padding: 0 12px;
  }
  .register-business__item-title {
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
  }
  .register-business__item-description p {
    margin-bottom: 0px !important;
  }
  .register-business__item-items {
    margin: 15px 0;
  }
  .register-business__item-item {
    padding: 0 !important;
  }
  .register-business__item-coupon {
    padding: 0 !important;
    color: green;
  }
  .register-business__item-amount {
    font-weight: bold;
    font-size: 18px;
  }
</style>

