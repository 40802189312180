<template>
  <div>
    <v-simple-table class="elevation-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Descrição</th>
            <th class="text-center">Data de Contratação</th>
            <th class="text-center">Valor Total</th>
            <th class="text-center">Situação</th>
            <th class="text-center">Opção</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sales" :key="item.name">
            <td>
              <v-icon :color="item.category.color">{{ item.category.icon }}</v-icon>
              {{ item.category.name }}
            </td>
            <td class="text-center">{{ item.opened_at }}</td>
            <td class="text-center">{{ item.amount | money }}</td>
            <td class="text-center">
              <strong>{{ item.situation }}</strong>
            </td>
            <td class="text-center">
              <v-btn
                color="primary"
                outlined
                link
                :to="{ name: 'sale', params: { id: item.id } }"
              >
                <v-icon>add</v-icon>
                Detalhes</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "SaleTable",
  props: ["sales"],
};
</script>

<style></style>
