<template>
  <v-form v-model="isValid" class="register-address">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Informe seu endereço</h2>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-address__col">
          <v-text-field v-model="form.zip_code" name="zip_code" v-on:keyup="searchCep" label="Qual seu CEP?" placeholder="Digite seu CEP aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]" v-mask="'#####-###'"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" class="register-address__col">
          <v-text-field v-model="form.address" name="address" label="Qual seu endereço?" placeholder="Digite seu endereço aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]" ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-address__col">
          <v-text-field v-model="form.number" name="number" label="Qual o número?" placeholder="Digite o número aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-address__col">
          <v-text-field v-model="form.complement" name="complement" label="Complemento. Ex: Apt., Bloco, etc" placeholder="Digite o Complemento aqui" :disabled="isSaved" :loading="loading" outlined block></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-address__col">
          <v-text-field v-model="form.district" name="district" label="Qual seu bairro?" placeholder="Digite seu bairro aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-address__col">
          <v-select v-model="form.city_id" name="city" :items="cityItems" label="Qual sua cidade?" placeholder="Selecione sua cidade" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-select>
        </v-col>
        <input v-model="form.person_id" type="hidden">
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import axios from 'axios'
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterAddress',
    data: () => ({
      stageName: 'address',
      cityItems: [],
      rules: {
        required: value => !!value || 'Campo obrigatório.'
      }
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.address 
        },
        set (value) {
          this.$store.commit('register/formAddress', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      person () {
        return this.$store.state.register.form.person 
      }
    },
    methods: {
      searchCep: function () {
        if (this.form && this.form.zip_code && this.form.zip_code.toString().length >= 9) {
          axios.get('https://viacep.com.br/ws/' + this.form.zip_code + '/json/')
          .then(response => {
            this.setAddressByApi(response.data)
          })
        }
      },
      setAddressByApi: function (data) {
        if (data && !data.erro) {
          this.form.address = data.logradouro || null
          this.form.district = data.bairro || null
          this.form.city_id = data.ibge ? parseInt(data.ibge) : null
        } else {
          this.form.address = null
          this.form.district = null
          this.form.city_id = null
        }
        this.$forceUpdate()
      },
      getCities: function () {
        this.$Auth.register('address/city', {}, 
        response => {
          if (response && response.data) {
            this.cityItems = response.data
          }
        })
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(4)
      this.getCities()
      this.setDataToForm(4, 'address')
      this.form.person_id = this.person.id
    },
    props: ['valid'],
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-address h2 {
    padding-bottom: 10px;
  }
  .register-address__col {
    padding: 0 12px;
  }
  .register-terms__topics {
    padding: 20px;
  }
  .register-terms__checkbox {
    text-align: center !important;
    align-content: center !important;
  }
</style>

