<template>
  <div>
    <v-dialog v-model="active" width="500">
      <v-card>
        <v-card-text class="text-center">
          <div class="coupon-dialog__content">
            <h2>Insira o código do seu cupom de desconto</h2>
            <v-text-field v-model="coupon.slug" name="cupom" label="Qual o código do cupom?" placeholder="Digite seu código do cupom" :disabled="loading" :loading="loading" outlined block></v-text-field>
            <div v-if="message" class="coupon-dialog__content-message">
              <h3 v-if="coupon.id" class="coupon-dialog__content-message--success" v-text="message"></h3>
              <h3 v-else class="coupon-dialog__content-message--error" v-text="message"></h3>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col style="padding: 0 5px 0 0;">
            <v-btn v-if="!coupon.id" block outlined color="red" @click="setActive(false)">Cancelar</v-btn>
            <v-btn v-else block color="green" dark @click="setActive(false)">Concluir</v-btn>
          </v-col>
          <v-col v-if="!coupon.id" style="padding: 0 0 0 5px;">
            <v-btn color="primary" block outlined :loading="loading" :disabled="!coupon.slug || coupon.id != undefined" @click="validateCouponSlug()">Aplicar cupom</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'CouponDialog',
    computed: {
      ...mapState('register/business/couponDialog', ['loading', 'message']),
      active: {
        get () {
          return this.$store.state.register.business.couponDialog.active
        },
        set (active) {
          this.$store.commit('register/business/couponDialog/active', active)
        }
      },
      coupon: {
        get () {
          return this.$store.state.register.business.couponDialog.coupon
        },
        set (coupon) {
          this.$store.commit('register/business/couponDialog/coupon', coupon)
        }
      }
    },
    methods: {
      ...mapActions('register/business/couponDialog', ['setActive', 'validateCouponSlug'])
    }
  }
</script>
<style scoped>
  .coupon-dialog__content {
    padding-top: 40px;
  }
  .coupon-dialog__content h2 {
    margin-bottom: 40px;
  }
  .coupon-dialog__content-message {
    border-style: solid;
    border-width: 1px;
  }
  .coupon-dialog__content-message--success {
    color: green;
  }
  .coupon-dialog__content-message--error {
    color: red;
  }
</style>