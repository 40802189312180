export default {
  data: () => {
    return {
      snackbar: false
    }
  },
  computed: {
    stage: {
      get () {
        var stage = this.$route.params.stage
        return parseInt(stage)
        // return this.$store.state.register.stage
      },
      set (stage) {
        this.$router.push({params: {stage: stage}})
        this.$store.dispatch('register/setStage', stage)
      }
    },
    form () {
      return this.$store.state.register.form
    },
    loading: {
      get () {
        return this.$store.state.register.loading
      },
      set (loading) {
        this.$store.commit('register/loading', loading)
      }
    }
  },
  methods: {
    action: function () {
      if (this.form[this.stageName] && !this.form[this.stageName].id) {
        this.sendDataToApi({url: this.stageName, data: this.form[this.stageName]})
      } else {
        this.nextStage()
      }
    },
    nextStage: function () {
      this.stage = this.stage + 1
      this.$forceUpdate()
    },
    saveStageDataInStore: function (stage, data) {
      this.$Storage.set('register-' + stage, data)
    },
    setDataToForm: function (stage, stageName) {
      var formData = this.$Storage.get('register-' + stage)
      if (formData) {
        this.$store.dispatch('register/setForm', { stageName: stageName, data: formData })
      }
      this.$forceUpdate()
    },
    setStorageDataInStore: function () {
      var graduation = this.$Storage.get('register-0')
      var terms = this.$Storage.get('register-1')
      var person = this.$Storage.get('register-2')
      var contact = this.$Storage.get('register-3')
      var address = this.$Storage.get('register-4')
      var grade = this.$Storage.get('register-5')
      var imageDoc = this.$Storage.get('register-6')
      var imageDocVerso = this.$Storage.get('register-7')
      var imagePersonDoc = this.$Storage.get('register-8')
      var business = this.$Storage.get('register-9')
      this.$store.dispatch('register/setForm', { stageName: 'graduation', data: graduation })
      this.$store.dispatch('register/setForm', { stageName: 'terms', data: terms })
      this.$store.dispatch('register/setForm', { stageName: 'person', data: person })
      this.$store.dispatch('register/setForm', { stageName: 'contact', data: contact })
      this.$store.dispatch('register/setForm', { stageName: 'address', data: address })
      this.$store.dispatch('register/setForm', { stageName: 'grade', data: grade })
      this.$store.dispatch('register/setForm', { stageName: 'image-doc', data: imageDoc })
      this.$store.dispatch('register/setForm', { stageName: 'image-doc-verso', data: imageDocVerso })
      this.$store.dispatch('register/setForm', { stageName: 'image-person-doc', data: imagePersonDoc })
      this.$store.dispatch('register/setForm', { stageName: 'business', data: business })
    },
    sendDataToApi: function ({url, data}) {
      this.loading = true
      this.$Auth.register(url, data,
      response => {
        setTimeout(() => {
          this.loading = false
        }, 5000)
        this.checkApiResponse(response)
        this.loading = false
      })
    },
    checkApiResponse: function (response) {
      if (response && response.data && response.data.status === 'success' && response.data.data) {
        this.saveStageDataInStore(this.stage, response.data.data)
        this.setDataToForm(this.stage, this.stageName)
        this.snackbar = true
        this.nextStage()
      } else {
        this.showMessageError(response)
      }
    },
    showMessageSuccess: function () {
      this.$swal.fire({
        icon: 'success',
        title: 'Formatura encontrada com sucesso!',
        text: 'Informe agora seus dados pessoais.',
        confirmButtonText: 'Ok, prosseguir'
      })
    },
    showMessageError: function (response) {
      this.$swal.fire({
        icon: 'error',
        title: response && response.data && response.data.message ? response.data.message : 'Oops! Ocorreu um erro.',
        text: 'Verifique os dados informados e tente novamente ou entre em contato conosco.'
      })
    },
    checkIfPreviousStageIsComplete: function (currentStage) {
      var previousStageData = this.$Storage.get('register-' + (currentStage - 1))
      if (!previousStageData) {
        this.$router.push({params: {stage: (currentStage - 1)}})
      }
    },
    checkIfRegisterRequiredIsExecuted: function () {
      const registerRequired = this.$Storage.get('register-0')
      if (!registerRequired) {
        this.$router.push({name: 'register-required'})
        window.location.reload()
      }
    },
    setAllSteres: function () {
      for (let stage = 0; stage < 9; stage++) {
        var formData = this.$Storage.get('register-' + stage)
        if (formData) {
          var stageName = null
          switch (stage) {
            case 0:
              stageName = 'graduation'
              break;
            case 1:
              stageName = 'terms'
              break;
            case 2:
              stageName = 'person'
              break;
            case 3:
              stageName = 'contact'
              break;
            case 4:
              stageName = 'address'
              break;
            case 5:
              stageName = 'grade'
              break;
            case 6:
              stageName = 'image-doc'
              break;
            case 7:
              stageName = 'image-doc-verso'
              break;
            case 8:
              stageName = 'image-person-doc'
              break;
            case 9:
              stageName = 'business'
              break;
          }
          this.$store.dispatch('register/setForm', { stageName: stageName, data: formData })
        } 
      }
    }
  }
}