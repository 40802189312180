<template>
  <v-app :style="config.style || style">
    <v-app-bar v-if="config.toolbar" app :dark="config.toolbar.dark" :inverted-scroll="false" :style="config.toolbar.style">
      <v-toolbar-title>
        <router-link :to="{name: 'home'}">
          <v-img :src="config.toolbar.logo.url || 'assets/logo/sua-logo-aqui.png'" :width="config.toolbar.logo.width || 70"/>
        </router-link>
      </v-toolbar-title>
      <v-toolbar-title>
        <router-link :to="{name: 'home'}">
          <span :style="{color: config.toolbar.text.color}" style="padding-left: 20px;" v-html="config.toolbar.title || 'Área do Cliente'"></span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text link class="d-none d-sm-flex" :to="{name: 'home'}" :style="{color: config.toolbar.text.color}">
        <v-icon>home</v-icon>
        <span>Home</span>
      </v-btn>
      <v-btn text class="d-none d-sm-flex" @click="logout()" :style="{color: config.toolbar.text.color}">
        <v-icon>exit_to_app</v-icon>
        <span>Sair</span>
      </v-btn>
      <v-btn icon link class="d-sm-none" :to="{name: 'home'}" :style="{color: config.toolbar.text.color}">
        <v-icon>home</v-icon>
      </v-btn>
      <v-btn icon class="d-sm-none" @click="logout()" :style="{color: config.toolbar.text.color}">
        <v-icon>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div v-if="config.logo" class="main__header">
              <router-link :to="{name: 'home'}">
                <img
                  :src="config.logo.url || 'assets/logo/sua-logo-aqui.png'"
                  :width="config.logo.width || 250"
                  :style="{
                    width: '100%',
                    'max-width': `${config.logo.width}px` || '250px',
                  }"
                />
              </router-link>
            </div>
            <div v-if="config.subHeader" class="main__sub-header">
              <div v-html="config.subHeader"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'Main',
    data: () => ({
      drawer: false,
      group: null,
      style: {
        'background': "linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('assets/background/login-background-falback.jpg')",
        'background-position': 'center',
        'background-size': 'cover'
      }
    }),
    computed: {
      config () {
        return this.$store.state.system.main
      }
    },
    methods: {
      logout: function () {
        this.$Auth.logout('ask')
      },
      checkIfRegisterIsCompleted: function () {
        let user = this.$Auth.user()
        if (user.register) {
          let register = user.register
          if (register.graduation) {
            this.$Storage.set('register-0', register.graduation)
          }
          if (register.terms) {
            this.$Storage.set('register-1', register.terms)
          }
          if (register.person) {
            this.$Storage.set('register-2', register.person)
          }
          if (register.contact) {
            this.$Storage.set('register-3', register.contact)
          }
          if (register.address) {
            this.$Storage.set('register-4', register.address)
          }
          if (register.grade) {
            this.$Storage.set('register-5', register.grade)
          }
          localStorage.removeItem('e465008c-f08c-445d-8795-c1b1b847009f')
          this.$router.push({name: 'register', params: {stage: 1}})
          this.$swal({
            title: 'Por favor, finalize seu cadastro.',
            text: 'Para acessar o portal, finalize seu cadastro.',
            icon: 'info'
          })
        }
      }
    },
    mounted: function () {
      this.checkIfRegisterIsCompleted()
    }
  }
</script>
<style scoped>
  .main__header {
    text-align: center;
    padding-top: 20px;
  }
  .main__sub-header {
    text-align: center;
    padding-top: 20px;
  }
</style>
<style>
  .swal2-popup {
    font-family: 'Roboto' !important;
  }
  a {
    text-decoration: none;
  }
</style>