<template>
  <v-app :style="loginStyle">
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" offset-md="4" offset-lg="4">
          <v-card :dark="config.dark" tile class="elevation-20">
            <v-card-text>
              <div class="login__content">
                <img :src="config.logo.url || 'assets/logo/sua-logo-aqui.png'" :width="config.logo.width || '150'"/>
                <h2>Por favor, crie uma<br>nova senha</h2>
                <v-form v-model="valid" ref="form">
                  <div class="login__input">
                    <v-text-field 
                      v-model="password.new"
                      @keyup.enter="validateNewPassword"
                      label="Nova senha"
                      placeholder="Insira a nova senha"
                      :append-icon="password.newShow ? 'visibility' : 'visibility_off'"
                      :type="password.newShow ? 'text' : 'password'"
                      @click:append="password.newShow = !password.newShow"
                      required
                      :rules="[v => !!v || 'Campo obrigatório', v => (v.length >= 8) || 'Deve ter no mínimo 8 caracteres']"
                    ></v-text-field>
                  </div>
                  <div class="login__input">
                    <v-text-field 
                      v-model="password.confirm" 
                      @keyup.enter="validateNewPassword" 
                      label="Confirmação de senha" 
                      placeholder="Insira sua novamente" 
                      required 
                      :rules="[v => (!!v || 'Campo obrigatória'), v => (v == password.new) || 'A senha não é igual']" 
                      type="password"
                    ></v-text-field>
                  </div>
                  <div v-if="valid" class="login__input">
                    <v-alert dense text type="success" icon="done_outline">
                      A nova senha é válida.
                    </v-alert>
                  </div>
                </v-form>
                <div class="login__button">
                  <v-btn color="primary" :disabled="!valid" :loading="loading" block @click="validateNewPassword()">Confirmar Alteração</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  export default {
    name: 'Password',
    data: () => ({
      valid: false,
      password: {
        new: '',
        confirm: '',
        newShow: false
      },
      loading: false
    }),
    computed: {
      config () {
        return this.$store.state.system.login
      },
      company () {
        return this.$store.state.system.company
      },
      loginStyle () {
        var backgroundImage = this.config.background.url || 'assets/background/login-background-falback.jpg'
        var backgroundGradient = this.config.background.gradient || '0, 0, 0, 0.5'
        return {
          'background': "linear-gradient(0deg, rgba(" + backgroundGradient + "), rgba(" + backgroundGradient + ")), url('" + backgroundImage + "')",
          'background-position': 'center',
          'background-size': 'cover'
        }
      }
    },
    methods: {
      validateNewPassword: function () {
        this.$refs.form.validate()
        if (this.valid) {
          this.postNewPassword()
        } else {
          this.$refs.form.validate()
        }
      },
      postNewPassword: function () {
        this.loading = true
        this.$Api.post({
          url: 'portal/password',
          data: {password: this.password.new},
          success: () => {
            this.loading = false
            setTimeout(() => {
              this.$Auth.clearSession()
            }, 1000);
          },
          error: err => {
            this.loading = false
            console.log(err)
          },
          config: {
            message: {
              success: {
                title: 'Senha alterada com sucesso!',
                text: 'Faça login novamente para continuar.'
              },
              error: true
            }
          }
        })
      }
    }
  }
</script>
<style scoped>
  .login__content {
    padding: 20px;
    text-align: center !important;
  }
  .login__content h2 {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    text-transform: uppercase;
    font-weight: initial;
  }
  .login__input {
    padding: 0 0 10px 0;
  }
  .password_background {
    background: black;
  }
</style>
