<template>
  <div class="loader-api">
    <div v-if="loading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="loader-api__loading-progress">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            </div>
            <div class="loader-api__loading-message">
              <h3>Buscando informações, por favor aguarde...</h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <slot :data="data" :refresh="loadApi"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'LoaderApi',
    data: () => ({
      loading: false,
      data: null
    }),
    computed: {
      config () {
        return this.$store.state.system.login
      }
    },
    methods: {
      loadApi: function () {
        if (this.apiUrl) {
          this.loading = true
          this.$Api.get({
            url: this.apiUrl,
            params: this.apiParams || {}, 
            success: response => {
              this.data = response.data
              this.$emit('onLoad', {data: response.data, refresh: this.loadApi})
              this.loading = false
            },
            error: err => {
              this.loading = false
              console.log(err)
            },
            config: {
              message: {
                loading: {
                  show: false
                }
              }
            }
          })
        } else {
          this.showMessageError()
        }
      },
      showMessageError: function () {
        this.$swal({
          type: 'error',
          title: 'Api Url not informed!',
          text: 'Try again!'
        })
      }
    },
    mounted: function () {
      this.loadApi()
    },
    props: ['apiUrl', 'apiParams'],
    watch: {
      '$route': 'loadApi'
    }
  }
</script>
<style scoped>
  .loader-api__loading-progress {
    text-align: center;
    margin: 50px 0;
  }
  .loader-api__loading-message {
    text-align: center;
    font-size: 20px;
    margin: 20px 0 40px 0;
  }
</style>