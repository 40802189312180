<template>
  <div>
    <h1 class="tickets__title">
      {{ title }}
    </h1>
    <div v-if="tickets && tickets.data">
      <div v-if="tickets.data.length > 0">
        <v-app-bar flat dense color="#C5C5C5">
          <v-toolbar-title>
            {{ tickets.data.length }} chamado(s)/contato(s) encontrados.
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <DialogCreateTicket
            v-if="tickets && tickets.data"
            :refreshTickets="tickets.refresh"
          ></DialogCreateTicket>
        </v-app-bar>
        <v-timeline v-if="!$isMobile" dense oposite>
          <v-timeline-item
            v-for="(ticket, ticketKey) in tickets.data"
            :key="ticketKey"
          >
            <TicketCard :ticket="ticket"></TicketCard>
          </v-timeline-item>
        </v-timeline>
        <div v-else v-for="(ticket, ticketKey) in tickets.data" :key="ticketKey">
          <TicketCard :ticket="ticket"></TicketCard>
        </div>
      </div>
      <div v-else>
        <TicketsBannerAlert
          :title="empytTitle"
          :tickets="tickets"
        />
      </div>
    </div>
  </div>          
</template>
<script>
import DialogCreateTicket from "@/pages/Tickets/DialogCreateTicket";
import TicketCard from "@/pages/Tickets/TicketCard";
import TicketsBannerAlert from "@/pages/Tickets/TicketsBannerAlert";
export default {
  name: "Tickets",
  data: () => ({}),
  methods: {},
  computed: {
    config() {
      return this.$store.state.system.login;
    },
  },
  props: {
    title: {
      type: String,
      default: "Chamados",
    },
    empytTitle: {
      type: String,
      default: "Nenhum chamado encontrado",
    },
    tickets: {
      default: []
    },
  },
  components: {
    TicketCard,
    DialogCreateTicket,
    TicketsBannerAlert,
  },
};
</script>
<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
.tickets__title {
  display: flex;
  justify-content: center;
  margin: 20px 0px 30px 0px;
}
</style>
