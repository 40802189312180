<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi apiUrl="portal/service-desk/ticket/opened">
              <template slot-scope="tickets">
                <Tickets
                  title="Chamados em aberto"
                  empytTitle="Você não possui chamados em aberto no momento..."
                  :tickets="tickets"
                ></Tickets>
              </template>
            </LoaderApi>
            <div class="tickets__actions">
              <v-btn color="primary" outlined link :to="{name: 'tickets-closed'}">
                <v-icon>history</v-icon>
                <span>Histórico de chamados</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Tickets from "@/pages/Tickets/Tickets";
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
export default {
  name: "TicketsOpened",
  components: {
    Tickets,
    LoaderApi
  },
};
</script>
<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
.tickets__actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
