<template>
  <v-simple-table class="elevation-3">
    <template v-slot:default>
      <thead v-if="commission && commission.data">
        <tr>
          <th class="text-center">NOME</th>
          <th class="text-center">TURMA</th>
          <!-- <th class="text-center">DESCRIÇÃO</th> -->
          <th class="text-center">SITUAÇÃO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, iKey) in commission.data" :key="iKey" class="text-center">
          <td>
            <strong>{{ item.name }}</strong>
          </td>
          <td>{{ item.grade }}</td>
          <!-- <td>{{ item.situation.description }}</td> -->
          <td>
            <span class="persons-table__status--paid" v-if="item.situation.status == 'paid'">
              Totalmente pago
            </span>
            <span
              class="persons-table__status--waiting"
              v-else-if="item.situation.status == 'waiting'"
            >
              Em dia
            </span>
            <span class="persons-table__status--expired" v-else>
              Em Atraso
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["commission"],
};
</script>

<style></style>
