<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center center-text" cols="12" sm="12" md="12" lg="12" xl="12">
        <h1 style="color:white;">Bem-vindo(a), {{userName}}</h1>
        <h2 style="color:white;">Você pode navegar clicando em um dos itens abaixo</h2>
      </v-col>
      <v-col v-if="config.buttons.tickets" cols="12" sm="6" md="4" lg="4" xl="3">
        <v-card class="home__item" link :to="{name: 'tickets'}" outlined color="white">
          <v-card-text>
            <v-icon color="white" x-large>email</v-icon>
            <div class="home__item-title">Minhas Solicitações</div>
            <div class="home__item-statistics">
              (<span> 20 </span>)
            </div>
          </v-card-text>
        </v-card>
      </v-col>
       <v-col v-if="config.buttons.sales" cols="12" sm="6" md="4" lg="4" xl="3">
        <v-card class="home__item" link :to="{name: 'sales'}" outlined color="white">
          <v-card-text>
            <v-icon color="white" x-large>event_note</v-icon>
            <div class="home__item-title">Minhas Contratações</div>
            <div class="home__item-statistics">
              (<span> 20 </span>)
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="config.buttons.finances" cols="12" sm="6" md="4" lg="4" xl="3">
        <v-card class="home__item" link :to="{name: 'finances'}" outlined color="white">
          <v-card-text>
            <v-icon color="white" x-large>local_atm</v-icon>
            <div class="home__item-title">Minhas Faturas</div>
            <div class="home__item-statistics">
              (<span> 20 </span>)
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'Home',
    data: () => ({
      userName: 'Formando(a)'
    }),
    computed: {
      config () {
        return this.$store.state.system.home
      }
    },
    methods: {
      setUserName: function () {
        let name = this.$Auth.user().name
        if (name) {
          this.userName = name.split(' ')[0]
        }
      }
    },
    mounted: function () {
      this.setUserName()
    }
  }
</script>
<style scoped>
  .home__logo {
    text-align: center;
    padding-top: 30px;
  }
  .home__item {
    padding: 20px 0;
    text-align: center;
    background: rgba(255, 255, 255, 0) !important;
    color: white !important;
  }
  .home__item-title {
    color: white;
    font-size: 25px;
    padding: 10px 0;
    text-transform: uppercase;
  }
  .home__item-statistics {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    display: none;
  }
</style>