<template>
  <v-form v-model="isValid" class="register-contact">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Informe seu telefone de contato</h2>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-contact__col">
          <v-select v-model="form.country_code1" :items="countryItems" label="Código do país" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="5" class="register-contact__col">
          <v-text-field v-model="form.contact1" name="phone" label="Qual seu celular?" placeholder="Digite seu celular" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required, rules.contactMin, rules.contactMax]" v-mask="'(##) # ####-####'"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-contact__col">
          <v-select v-model="form.is_whatsapp1" name="whatsapp" :items="typeItems" label="Este número é WhatsApp?" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-select>
        </v-col>
        <v-col cols="12">
          <h2>Informe um outro número para entrarmos em contato se necessário</h2>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-contact__col">
          <v-select v-model="form.country_code2" :items="countryItems" label="Código do país" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="5" class="register-contact__col">
          <v-text-field v-model="form.contact2" name="phone" label="Qual seu celular?" placeholder="Digite seu celular" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required, rules.contactMin, rules.contactMax]" v-mask="'(##) # ####-####'"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-contact__col">
          <v-select v-model="form.is_whatsapp2" name="whatsapp" :items="typeItems" label="Este número é WhatsApp?" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterContact',
    data: () => ({
      stageName: 'contact',
      countryItems: [
        {value: 55, text: 'Brasil'},
        {value: 1, text: 'Estados Unidos'},
        {value: 351, text: 'Portugal'},
      ],
      typeItems: [
        {value: 'yes', text: 'Sim, é WhatsApp'},
        {value: 'no', text: 'Não, este número não é WhatsApp'}
      ],
      rules: {
        contactMin: v => v && v.length >= 15 || 'Mínimo de 10 Caractere',
        contactMax: v => v && v.length <= 17 || 'Máximo de 11 Caractere',
        required: value => !!value || 'Campo obrigatório.'
      }
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.contact 
        },
        set (value) {
          this.$store.commit('register/formContact', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      person () {
        return this.$store.state.register.form.person 
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(3)
      this.setDataToForm(3, 'contact')
      this.form.person_id = this.person.id
    },
    props: ['valid'],
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-contact h2 {
    padding-bottom: 10px;
  }
  .register-contact__col {
    padding: 0 12px;
  }
</style>

