import Vue from 'vue'
import Vuex from 'vuex'
import system from '@/default/store/system/system'
import register from '@/default/store/register/register'
import stores from '@/store/store'

Vue.use(Vuex)

const modules = {
  system,
  register
}

for (const key in stores) {
  modules[key] = stores[key]
}

const store = new Vuex.Store({
  state: {api: window.env.api},
  modules: modules
})

export default store