import business from '@/default/store/register/stages/business/business'

export default {
  namespaced: true,
  state: {
    stage: 1,
    stageName: 'terms',
    form: {
      graduation: {
        graduation_code: null
      },
      terms: {},
      person: {},
      address: {
        person_id: null
      },
      contact: {
        person_id: null,
        country_code1: 55,
        country_code2: 55
      },
      grade: {},
      'image-doc': {
        url: null
      },
      'image-doc-verso': {
        url: null
      },
      'image-person-doc': {
        url: null
      },
      business: {
        coupon_id: null
      }
    },
    loading: false
  },
  mutations: {
    stage (state, data) {
      state.stage = data
    },
    stageName (state, data) {
      state.stageName = data
    },
    form (state, {stageName, data}) {
      state.form[stageName] = data
    },
    formGraduation (state, data) {
      state.form.graduation = data
    },
    formTerms (state, data) {
      state.form.terms = data
    },
    formPerson (state, data) {
      state.form.person = data
    },
    formAddress (state, data) {
      state.form.address = data
    },
    formContact (state, data) {
      state.form.contact = data
    },
    formGrade (state, data) {
      state.form.grade = data
    },
    'formimage-doc' (state, data) {
      state.form['image-doc'] = data
    },
    'formimage-doc-verso'(state, data) {
      state.form['image-doc-verso'] = data
    },
    'formimage-person-doc' (state, data) {
      state.form['image-person-doc'] = data
    },
    formBusiness (state, data) {
      state.form.business = data
    },
    loading (state, data) {
      state.loading = data
    },
    setBusinessCoupon (state, data) {
      state.form.business['coupon_id'] = data
    }
  },
  actions: {
    setStage: function ({ commit, dispatch }, stage) {
      commit('stage', stage)
      dispatch('setStageName', stage)
    },
    setStageName: function ({ commit }, stage) {
      switch (stage) {
        case 0:
          commit('stageName', 'graduation')
          break;
        case 1:
          commit('stageName', 'terms')
          break;
        case 2:
          commit('stageName', 'person')
          break;
        case 3:
          commit('stageName', 'contact')
          break;
        case 4:
          commit('stageName', 'address')
          break;
        case 5:
          commit('stageName', 'grade')
          break;
      }
    },
    setForm: function ({ commit }, {stageName, data}) {
      if (data) {
        commit('form', { stageName: stageName, data: data })
      }
    }
  },
  modules: {
    business
  }
}
