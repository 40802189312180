<template>
  <v-app :style="loginStyle">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="10" lg="10" class="register">
          <div class="register__content">
            <v-card class="elevation-20">
              <v-stepper v-model="stage" vertical>
                <v-stepper-step id="register-1" :complete="getStageStatus(1)" step="1">
                  <RegisterHeader :status="getStageStatus(1)" title="Termos de uso" description="Termos de uso do portal"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="1">
                  <RegisterItem :step="1" stageName="terms"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-2" :complete="getStageStatus(2)" step="2">
                  <RegisterHeader :status="getStageStatus(2)" title="Dados pessoais" description="Informe seu nome, CPF, etc"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="2">
                  <RegisterItem :step="2" stageName="person"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-3" :complete="getStageStatus(3)" step="3">
                  <RegisterHeader :status="getStageStatus(3)" title="Dados de contato" description="Informe seu celular/WhatsApp"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="3">
                  <RegisterItem :step="3" stageName="contact"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-4" :complete="getStageStatus(4)" step="4">
                  <RegisterHeader :status="getStageStatus(4)" title="Endereço" description="Informe onde você mora"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="4">
                  <RegisterItem :step="4" stageName="address"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-5" :complete="getStageStatus(5)" step="5">
                  <RegisterHeader :status="getStageStatus(5)" title="Turma" description="Informe sua turma"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="5">
                  <RegisterItem :step="5" stageName="grade"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-6" :complete="getStageStatus(6)" step="6">
                  <RegisterHeader :status="getStageStatus(6)" title="Foto do seu documento - FRENTE" description="Frente do seu RG ou CNH"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="6">
                  <RegisterItem :step="6" stageName="image-doc"></RegisterItem>
                </v-stepper-content>

                <v-stepper-step id="register-7" :complete="getStageStatus(7)" step="7">
                  <RegisterHeader :status="getStageStatus(7)" title="Foto do seu documento - VERSO" description="Verso do seu RG ou CNH"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="7">
                  <RegisterItem :step="7" stageName="image-doc-verso"></RegisterItem>
                </v-stepper-content>

                <v-stepper-step id="register-8" :complete="getStageStatus(8)" step="8">
                  <RegisterHeader :status="getStageStatus(8)" title="Foto segurando o documento" description="Segurando o RG ou CNH"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="8">
                  <RegisterItem :step="8" stageName="image-person-doc"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-9" :complete="getStageStatus(9)" step="9">
                  <RegisterHeader :status="getStageStatus(9)" title="Plano de pagamento" description="Selecione seu plano de pagamento"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="9">
                  <RegisterItem :step="9" stageName="business"></RegisterItem>
                </v-stepper-content>
                <v-stepper-step id="register-10" :complete="getStageStatus(10)" step="10">
                  <RegisterHeader :status="getStageStatus(10)" title="Cadastro concluído" description="Seu cadastro foi concluído com sucesso!"></RegisterHeader>
                </v-stepper-step>
                <v-stepper-content step="10">
                  <RegisterItem :step="10" stageName="finish" :finish="true"></RegisterItem>
                </v-stepper-content>
              </v-stepper>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  import RegisterHeader from '@/default/pages/Register/RegisterHeader'
  import RegisterItem from '@/default/pages/Register/RegisterItem'
  export default {
    name: 'Register',
    data: () => ({}),
    computed: {
      config () {
        return this.$store.state.system.login
      },
      loginStyle () {
        var backgroundImage = this.config.background.url || 'assets/background/login-background-falback.jpg'
        var backgroundGradient = this.config.background.gradient || '0, 0, 0, 0.5'
        return {
          'background': "linear-gradient(0deg, rgba(" + backgroundGradient + "), rgba(" + backgroundGradient + ")), url('" + backgroundImage + "')",
          'background-position': 'center',
          'background-size': 'cover'
        }
      }
    },
    methods: {
      getStageStatus: function (id) {
        var stageData = this.$Storage.get('register-' + id)
        return stageData != null || stageData != undefined
      },
      scrolToStageSession: function (route) {
        setTimeout(() => {
          this.$vuetify.goTo('#register-' + route.params.stage)
        }, 800)
      },
      setDataInStages: function () {
        this.setDataToForm(0, 'graduation')
        this.setDataToForm(1, 'terms')
        this.setDataToForm(2, 'person')
        this.setDataToForm(3, 'contact')
        this.setDataToForm(4, 'address')
        this.setDataToForm(5, 'grade')
      }
    },
    mounted: function () {
      this.checkIfRegisterRequiredIsExecuted()
      this.setDataInStages()
    },
    watch: {
      '$route': 'scrolToStageSession'
    },
    mixins: [RegisterMixin],
    components: {
      RegisterHeader,
      RegisterItem
    }
  }
</script>
<style scoped>
  .register {
    padding: 12px 0 !important;
  }
  .register__content {
    text-align: center !important;
  }
</style>
