<template>
  <v-card class="ticket-card__card--mobile elevation-15">
    <v-card-title v-if="ticket.title" class="headline">
      <span>{{ ticket.title }}</span>
    </v-card-title>
    <v-divider v-if="ticket.title"></v-divider>
    <v-card-title class="headline">
      <span>{{ ticket.category.name }}</span>
      <v-spacer></v-spacer>
      <div class="tickets__status">
        <v-icon :color="ticket.status.color"> {{ ticket.status.icon }} </v-icon>
        <span> {{ ticket.status.name }} </span>
      </div>
    </v-card-title>
    <v-card-text>
      <div><strong>Data de Criação:</strong> {{ ticket.created_at_formated }}</div>
      <div><strong>Data Última interação: </strong> {{ ticket.last_event_time_formated }}</div>
      <p>
        <strong>Última interação:</strong>
        <span v-if="ticket.last_note" v-html="ticket.last_note.description_time"></span>
        <span v-else v-html="ticket.description_time"></span>
      </p>
      <v-btn
        outlined
        dense
        color="primary"
        link
        :to="{ name: 'ticket', params: { id: ticket.uuid } }"
      >
        Detalhes
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "TicketCard",
  props: ["ticket"],
};
</script>
<style>
.ticket-card__card--mobile {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
