<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon large :color="color">
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span>{{ title }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <strong>{{ description }}</strong>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
  export default {
    name: 'TicketDashboardItem',
    props: ['color', 'icon', 'title', 'description']
  }
</script>