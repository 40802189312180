<template>
  <div>
    <v-card color="grey lighten-4" flat>
      <v-card-text class="register-item">
        <RegisterTerms v-if="step === 1 && stage === 1" :valid="valid" v-on:valid="setValid"></RegisterTerms>
        <RegisterPerson v-if="step === 2 && stage === 2" :valid="valid" v-on:valid="setValid"></RegisterPerson>
        <RegisterContact v-if="step === 3 && stage === 3" :valid="valid" v-on:valid="setValid"></RegisterContact>
        <RegisterAddress v-if="step === 4 && stage === 4" :valid="valid" v-on:valid="setValid"></RegisterAddress>
        <RegisterGrade v-if="step === 5 && stage === 5" :valid="valid" v-on:valid="setValid"></RegisterGrade>
        <RegisterImage v-if="step === 6 && stage === 6" :valid="valid" v-on:valid="setValid" :step="6" stageName="image-doc" title="Por favor, envie uma foto da frente do seu RG ou CNH." imagePlaceholder="doc-front.png"></RegisterImage>
        <RegisterImage v-if="step === 7 && stage === 7" :valid="valid" v-on:valid="setValid" :step="7" stageName="image-doc-verso" title="Por favor, envie uma foto do verso do seu RG ou CNH." imagePlaceholder="doc-verse.png"></RegisterImage>
        <RegisterImage v-if="step === 8 && stage === 8" :valid="valid" v-on:valid="setValid" :step="8" stageName="image-person-doc" title="Por favor, envie uma foto selfie segurando seu documento RG ou CNH." imagePlaceholder="doc-person.png"></RegisterImage>
        <RegisterBusiness v-if="step === 9 && stage === 9" :valid="valid" v-on:valid="setValid"></RegisterBusiness>
        <RegisterFinish v-if="step === 10 && stage === 10"></RegisterFinish>
      </v-card-text>
    </v-card>
    <div class="register-item__action">
      <v-btn v-if="!finish" color="primary" block :disabled="!valid" :loading="loading" @click="action()">próximo</v-btn>
      <v-btn v-else color="primary" block @click="finishRegister()">ir para login</v-btn>
    </div>
    <v-snackbar dark top v-model="snackbar">
      Tudo certo, vamos agora para a próxima etapa.
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">Fechar</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  import RegisterTerms from '@/default/pages/Register/Stages/RegisterTerms'
  import RegisterPerson from '@/default/pages/Register/Stages/RegisterPerson'
  import RegisterContact from '@/default/pages/Register/Stages/RegisterContact'
  import RegisterAddress from '@/default/pages/Register/Stages/RegisterAddress'
  import RegisterGrade from '@/default/pages/Register/Stages/RegisterGrade'
  import RegisterImage from '@/default/pages/Register/Stages/RegisterImage'
  import RegisterBusiness from '@/default/pages/Register/Stages/RegisterBusiness'
  import RegisterFinish from '@/default/pages/Register/Stages/RegisterFinish'
  export default {
    name: 'RegisterItem',
    data: () => ({
      valid: false
    }),
    methods: {
      setValid: function (valid) {
        this.valid = valid
      },
      finishRegister: function () {
        localStorage.clear()
        this.$router.push({name: 'login'})
        window.location.reload()
      }
    },
    mounted: function () {
      this.setAllSteres()
      this.setStorageDataInStore()
    },
    props: ['step', 'finish', 'stageName'],
    mixins: [RegisterMixin],
    components: {
      RegisterTerms,
      RegisterPerson,
      RegisterContact,
      RegisterAddress,
      RegisterGrade,
      RegisterImage,
      RegisterBusiness,
      RegisterFinish
    }
  }
</script>
<style scoped>
  .register-item {
    padding: 20px 0 !important;
  }
  .register-item__title {
    text-transform: uppercase;
    padding-bottom: 10px !important;
  }
  .register-item__action {
    padding: 20px 0;
  }
</style>
