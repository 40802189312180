import ptBR from '@/default/lang/langs/pt-BR'
import ptPT from '@/default/lang/langs/pt-PT'
import enUS from '@/default/lang/langs/en-US'

export default {
  'pt-BR': ptBR,
  'pt-PT': ptPT,
  'en-US': enUS
}

