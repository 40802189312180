<template>
  <v-form v-model="isValid" class="register-terms">
    <h2>
      Para continuar você precisa estar de acordo com a 
      <a target="_blank" href="/assets/register/politica-de-protecao-de-dados-pessoais-e-privacidade.pdf"> Política de Proteção de Dados Pessoais e Privacidade</a> e com o 
      <a target="_blank" href="/assets/register/contrato-individual-e-condicoes-gerais-de-uso.pdf"> Contrato Individual/Condições de Uso</a>.
    </h2>
    <div class="register-terms__topics">
      <img class="d-none d-md-flex" src="/assets/register/terms.jpeg" alt="Resumo dos termos de uso">
      <img class="d-md-none" src="/assets/register/terms-mobile.jpeg" alt="Resumo dos termos de uso">
    </div>
    <h2 class="register-terms__checkbox" v-if="form">
      <v-checkbox v-model="form.accept_terms" :disabled="isSaved" color="primary" off-icon="check_box_outline_blank" on-icon="check_box" label="Li e concordo com a " :rules="[v => !!v || 'Para avançar você precisa concordar com a Política de Proteção de Dados Pessoais e Privacidade e com o Contrato Individual e Condições de Uso']"></v-checkbox>
      <a target="_blank" href="/assets/register/politica-de-protecao-de-dados-pessoais-e-privacidade.pdf"> Política de Proteção de Dados Pessoais e Privacidade</a> e com o 
      <a target="_blank" href="/assets/register/contrato-individual-e-condicoes-gerais-de-uso.pdf"> Contrato Individual/Condições de Uso</a>
    </h2>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterTerms',
    data: () => ({
      stageName: 'terms'
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.terms 
        },
        set (value) {
          this.$store.commit('register/formTerms', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      }
    },
    methods: {
      setUserIndetificationData: function () {
        this.form.user_agent = navigator.userAgent
        this.form.date_and_hour = this.getFormattedDate()
        this.setUserLocation()
      },
      setUserLocation: function () {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            if (position.coords) {
              this.form.latitude = position.coords.latitude
              this.form.longitude = position.coords.longitude
            }
          });
        }
      },
      getFormattedDate: function () {
          var d = new Date()
          d = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2) + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2) + ":" + ('0' + d.getSeconds()).slice(-2)
          return d
      }
    },
    props: ['valid'],
    mounted: function () {
      this.checkIfRegisterRequiredIsExecuted()
      this.setDataToForm(1, 'terms')
      this.setUserIndetificationData()
    },
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-terms {
    text-align: left;
    padding: 0 20px;
  }
  .register-terms h2 {
    padding-bottom: 10px;
  }
  .register-terms__topics {
    padding: 20px 0;
  }
  .register-terms__topics img {
    width: 100%;
    max-width: 650px;
  }
  .register-terms__checkbox {
    text-align: left !important;
    align-content: left !important;
  }
</style>