<template>
  <v-form v-model="isValid" class="register-image-doc">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <h2 v-html="title"></h2>
          <h3 v-if="!form.id">Clique para buscar imagem.</h3>
        </v-col>
        <v-col v-if="!form.url" cols="12" sm="8" md="6" lg="4" class="register-image-doc__col">
          <br>
          <img v-if="selectedFullUrl" @click="selectImage()" :src="selectedFullUrl" class="register-image-doc__img" alt="">
          <img v-else @click="selectImage()" :src="'/assets/register/' + imagePlaceholder" class="register-image-doc__img register-image-doc__img--placeholder" alt=""/>
          <br><br>
          <div class="register-image-doc__button">
            <v-btn v-if="!selectedFullUrl" color="primary" @click="selectImage()">
              <v-icon>search</v-icon>
              Buscar imagem
            </v-btn>
          </div>
          <v-file-input v-model="selectedFile" id="file1" hidden type="file" class="register-image-doc__file-input" @change="onFileSelected" ref="fileImage" hide-input required :rules="[rules.required]" accept="image/*"></v-file-input>
          <input v-model="form.person_id" type="hidden">
        </v-col>
        <v-col v-else cols="12" sm="8" md="6" lg="4" class="register-image-doc__col">
          <img :src="form.url" class="register-image-doc__img--created" alt="">
        </v-col>
        <v-col v-if="selectedFullUrl && !form.url" cols="12">
          <br>
          <h2>A imagem está legível?</h2>
          <h3>Se necessário clique na imagem para selecionar outra.</h3>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterImage',
    data: () => ({
      selectedFullUrl: null,
      selectedFile: null,
      rules: {
        required: value => !!value || 'Campo obrigatório.'
      }
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form[this.stageName]
        },
        set (value) {
          this.$store.commit('register/form' + this.stageName, value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      person () {
        return this.$store.state.register.form.person 
      },
      terms () {
        return this.$store.state.register.form.terms 
      }
    },
    methods: {
      onFileSelected: function (ev) {
        this.selectedFullUrl = URL.createObjectURL(ev)
        let formData = new FormData();
        formData.append("file", this.selectedFile)
        formData.append("person_id", this.person.id)
        formData.append("terms_id", this.terms.id)
        this.$store.commit('register/form' + this.stageName, formData)
        this.$forceUpdate()
      },
      selectImage: function () {
        document.getElementById('file1').click()
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(this.step)
      this.setDataToForm(this.step, this.stageName)
      this.form.person_id = this.person.id
      this.form.terms_id = this.terms.id
    },
    props: ['step', 'valid', 'stageName', 'title', 'imagePlaceholder'],
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-contact h2 {
    padding-bottom: 10px;
  }
  .register-image-doc__col {
    padding: 0 12px;
    text-align: center;
  }
  .register-image-doc__img {
    width: 100%;
    cursor: pointer;
  }
  .register-image-doc__img--created {
    width: 100%;
  }
  .register-image-doc__file-input {
    display: none;
  }
  .register-image-doc__img--placeholder {
    max-width: 200px;
  }
  .register-image-doc__button {
    margin: 0px 0;
  }
</style>

