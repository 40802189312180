<template>
  <v-card v-if="ticket">
    <v-card-title v-if="ticket.title">
      <!-- <span v-if="ticket.protocol" class="grey--text">
        <strong>Protocolo: </strong>
        <span class="ml-1"> {{ ticket.protocol }}</span>
      </span>
      <v-spacer></v-spacer> -->
      <span class="ticket-dashboard__title">
        {{ ticket.title }}
      </span>
    </v-card-title>
    <v-divider v-if="ticket.title"></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <TicketDashboardItem
            :color="ticket.category.color"
            :icon="ticket.category.icon"
            title="Categoria"
            :description="ticket.category.name"
          ></TicketDashboardItem>
        </v-col>
        <v-col>
          <TicketDashboardItem
            :color="ticket.status.color"
            :icon="ticket.status.icon"
            title="Status/Etapa"
            :description="ticket.status.name"
          ></TicketDashboardItem>
        </v-col>
        <v-col>
          <TicketDashboardItem
            icon="today"
            title="Data de Abertura"
            :description="ticket.created_at"
          ></TicketDashboardItem>
        </v-col>
        <v-col>
          <TicketDashboardItem
            icon="schedule"
            title="Última Interação"
            :description="ticket.last_event_time"
          ></TicketDashboardItem>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider v-if="config.actions.close && ticket.stage === 1"></v-divider>
    <v-card-actions v-if="config.actions.close && ticket.stage === 1">
      <v-spacer></v-spacer>
      <v-btn
        :loading="closeTicketLoading"
        :disabled="ticket.stage !== 1"
        outlined
        color="orange"
        @click="closeTicketConfirm()"
      >
        <v-icon>done_outline</v-icon>
        <span style="padding-left: 5px;">Encerrar/Concluir Chamado</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
  import TicketDashboardItem from '@/pages/Ticket/TicketDashboardItem'
  export default {
    name: 'TicketDashboard',
    data: () => ({
      closeTicketLoading: false
    }),
    computed: {
      config () {
        return this.$store.state.system.ticket
      }
    },
    methods: {
      closeTicket: function () {
        this.closeTicketLoading = true
        this.$Api.put({
          url: 'portal/service-desk/ticket/' + this.$route.params.id + '/close',
          data: {},
          success: () => {
            this.refresh()
            this.closeTicketLoading = false
          },
          error: () => {
            this.closeTicketLoading = false
          },
          config: {
            message: {
              loading: {
                show: false
              }
            }
          }
        })
      },
      closeTicketConfirm: function () {
        this.$swal({
          title: 'Deseja realmente encerrar este chamado?',
          text: 'Esta ação pode ser irreversível!',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim! Sei o que estou fazendo',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.closeTicket()
          }
        })
      }
    },
    props: ['ticket', 'refresh'],
    components: {
      TicketDashboardItem
    }
  }
</script>
<style scoped>
  .ticket-dashboard__title {
    text-transform: capitalize
  }
</style>