<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi :apiUrl="'portal/finances/finance'">
              <template slot-scope="finances">
                <!-- <v-btn @click="finances.refresh()"></v-btn> -->
                <div v-if="finances && finances.data">
                  <FinancesTable
                    :finances="finances.data"
                    :refresh="finances.refresh"
                  ></FinancesTable>
                </div>
              </template>
            </LoaderApi>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
import FinancesTable from "@/default/components/Finance/FinancesTable";
export default {
  name: "Finances",

  data: () => ({
    disabled: null,
    category: {},
  }),
  methods: {},

  computed: {
    config() {
      return this.$store.state.system.login;
    },
  },
  components: {
    LoaderApi,
    FinancesTable,
  },
};
</script>

<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
.itemPag {
  color: green;
}
</style>
