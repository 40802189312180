<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-fade-transition>
        <v-icon v-if="status" x-large color="green">done_outline</v-icon>
        <v-icon v-else x-large color="orange">schedule</v-icon>
      </v-fade-transition>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="title"></v-list-item-title>
      <v-list-item-subtitle v-text="description"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
  export default {
    name: 'RegisterHeader',
    data: () => ({}),
    props: {
      status: {
        type: Boolean,
        default: false
      },
      title: {
        type: String
      },
      description: {
        type: String
      }
    }
  }
</script>
