import Home from "@/pages/Home/Home";
import Ticket from "@/pages/Ticket/Ticket";
import TicketsOpened from "@/pages/Tickets/TicketsOpened";
import TicketsClosed from "@/pages/Tickets/TicketsClosed";
import Sales from "@/pages/Sales/Sales";
import Sale from "@/pages/Sale/Sale";
import Finances from "@/pages/Finances/Finances";
import Persons from "@/pages/Persons/Persons";
import GraduationReport from "@/pages/Graduation/GraduationReport";

export default [
  {
    path: "home",
    name: "home",
    component: Home,
    meta: {
      auth: true,
      title: "Home",
      icon: "home",
      toolbarColor: 0,
    },
  },
  {
    path: "tickets",
    name: "tickets",
    component: TicketsOpened,
    meta: {
      auth: true,
      title: "Chamados/Contatos Abertos",
      icon: "email",
      toolbarColor: "primary",
    },
  },
  {
    path: "tickets-closed",
    name: "tickets-closed",
    component: TicketsClosed,
    meta: {
      auth: true,
      title: "Chamados/Contatos Encerrados",
      icon: "email",
      toolbarColor: "primary",
    },
  },
  {
    path: "ticket/:id",
    name: "ticket",
    component: Ticket,
    meta: {
      auth: true,
      title: "Detalhes do Chamado",
      icon: "email",
      toolbarColor: "primary",
    },
  },

  {
    path: "sales",
    name: "sales",
    component: Sales,
    meta: {
      auth: true,
      title: "Sales",
      icon: "sales",
      toolbarColor: 0,
    },
  },
  {
    path: "sale/:id",
    name: "sale",
    component: Sale,
    meta: {
      auth: true,
      title: "Detalhes da Contratação",
      icon: "sale",
      toolbarColor: 0,
    },
  },
  {
    path: "finances",
    name: "finances",
    component: Finances,
    meta: {
      auth: true,
      title: "Finances",
      icon: "finances",
      toolbarColor: 0,
    },
  },
  {
    path: "persons",
    name: "persons",
    component: Persons,
    meta: {
      auth: true,
      title: "Persons",
      icon: "persons",
      toolbarColor: 0,
    },
  },
  {
    path: "graduation-report",
    name: "graduation-report",
    component: GraduationReport,
    meta: {
      auth: true,
      title: "graduation-report",
      icon: "graduationReport",
      toolbarColor: 0,
    },
  },
];
