<template>
  <v-app :style="loginStyle">
    <v-container>
      <v-row no-gutters align-content="center" align="center">
        <!-- <v-col cols="12" sm="12" md="8" lg="8" xl="8">
          <div class="login__content" v-html="config.content.html"></div>
        </v-col> -->
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" offset-md="4" offset-lg="4" offset-xl="4" align-self="center">
          <v-card :dark="config.dark" tile class="elevation-20">
            <v-card-text>
              <div class="login__content">
                <img :src="config.logo.url || 'assets/logo/sua-logo-aqui.png'" :width="config.logo.width || '150'"/>
                <h2 v-html="config.title || 'Área do cliente'"></h2>
                <v-form v-model="valid">
                  <div class="login__input">
                    <v-text-field v-model="login.username" @keyup.enter="executeLogin" label="Login" placeholder="Insira seu login/email" :rules="[v => !!v || 'O login é obrigatório']"></v-text-field>
                  </div>
                  <div class="login__input">
                    <v-text-field 
                      v-model="login.password" 
                      @keyup.enter="executeLogin" 
                      label="Senha" 
                      placeholder="Insira sua senha" 
                      :rules="[v => !!v || 'A senha é obrigatória']" 
                      :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'">
                    </v-text-field>
                  </div>
                  <!-- <div v-if="config.terms.show" class="login__checkbox">
                    <v-checkbox :dark="config.dark" color="primary" off-icon="check_box_outline_blank" on-icon="check_box" v-model="login.accept_termos" :rules="[v => !!v || 'Para acessar você precisa aceitar os termos de uso']">
                      <template v-if="config.terms && config.terms.show" v-slot:label>
                        <div>
                          <span>Li e concordo com os </span>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <a target="_blank" :href="config.terms.url || ''" @click.stop v-on="on"> termos de uso</a>
                            </template>
                            <span>Abrir em uma nova página.</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </div> -->
                </v-form>
                <div class="login__button">
                  <v-btn color="primary" :disabled="!valid" :loading="loading" block @click="executeLogin()">Acessar</v-btn>
                </div>
                <!-- <div v-if="config.forgoutPassword" class="login__link">
                  <a href="">Esqueci minha senha</a>
                </div> -->
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  export default {
    name: 'Login',
    data: () => ({
      valid: false,
      login: {
        username: null,
        password: null,
        accept_termos: false
      },
      loading: false,
      showPassword: false
    }),
    computed: {
      config () {
        return this.$store.state.system.login
      },
      loginStyle () {
        var backgroundImage = this.config.background.url || 'assets/background/login-background-falback.jpg'
        var backgroundGradient = this.config.background.gradient || '0, 0, 0, 0.5'
        return {
          'background': "linear-gradient(0deg, rgba(" + backgroundGradient + "), rgba(" + backgroundGradient + ")), url('" + backgroundImage + "')",
          'background-position': 'center',
          'background-size': 'cover'
        }
      },
      company () {
        return this.$store.state.system.company
      }
    },
    methods: {
      executeLogin: function () {
        if (this.login.username && this.login.password) {
          this.loading = true
          this.$Auth.authentication(this.login, response => {
            this.checkLoginResult(response)
            this.loading = false
          })
        }
      },
      checkLoginResult: function (loginResponse) {
        if (loginResponse && loginResponse.status === 'success') {
          if (loginResponse.password_change) {
            this.$router.push({name: 'password'})
          } else {
            this.$router.push({name: 'home'})
          }
        } else {
          this.login.password = null
          this.$swal({
            icon: 'warning',
            title: 'Usuário não existe ou senha inválida!',
            text: 'Tente novamente ou contacte o suporte.'
          })
        }
      }
    }
  }
</script>
<style scoped>
  .login__content {
    padding: 20px;
    text-align: center !important;
  }
  .login__content h2 {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    text-transform: uppercase;
    font-weight: initial;
  }
  .login__checkbox .v-input--selection-controls {
    margin-top: 0px !important;
  }
  .login__link {
    text-align: center;
    padding: 20px 0;
  }
</style>
