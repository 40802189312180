import Vue from 'vue'
import {Interceptors} from '@/default/services/Interceptors'

export class Api {
  static get({url, params, success, error, config}) {
    this.messageLoading(config)
    Interceptors.Axios({url: this.getFullUrl(url, params)}).get()
    .then((data) => {
      this.action(success, data)
      this.message('success', config)
    })
    .catch((err) => {
      this.action(error, err)
      this.message('error', config)
    })
  }
  static post({url, params, data, success, error, config}) {
    this.messageLoading(config)
    Interceptors.Axios({url: this.getFullUrl(url, params), data: data}).post(this.getFullUrl(url, params), data)
    .then((data) => {
      this.action(success, data)
      this.message('success', config)
    })
    .catch((err) => {
      this.action(error, err)
      this.message('error', config)
    })
  }
  static put({url, params, data, success, error, config}) {
    this.messageLoading(config)
    Interceptors.Axios({url: this.getFullUrl(url, params), data: data}).put(this.getFullUrl(url, params), data)
    .then((data) => {
      this.action(success, data)
      this.message('success', config)
    })
    .catch((err) => {
      this.action(error, err)
      this.message('error', config)
    })
  }
  static getFullUrl (url, params) {
    const queryString = this.makeQueryString(params)
    return this.getBaseUrl() + url + queryString
  }
  static makeQueryString (params) {
    var queryString = ''
    if (params) {
      var count = 0
      queryString = '?'
      for (var key in params) {
        if (params[key]) {
          if (count) {
            queryString += '&'
          }
          queryString += key + '=' + params[key]
          count++
        }
      }
    }
    return queryString
  }
  static action (action, data) {
    if (action) {
      action(data)
    }
  }
  static message (type, config) {
    if (config && config.message && config.message[type]) {
      var defaultText = this.getDefaultTextMessages(type)
      Vue.swal({
        icon: type,
        title: config.message[type].title || defaultText.title,
        text: config.message[type].text || defaultText.text
      })
    } else {
      Vue.swal.close()
    }
  }
  static messageLoading (config) {
    if ((!config || !config.message || !config.message.loading) || (config && config.message && config.message.loading && config.message.loading.show)) {
      var text = {
        title: 'Carregando...',
        text: 'Aguarde até que a ação seja finalizada.'
      }
      if (config && config.message && config.message.loading) {
        if (config.message.loading.title) {
          text.title = config.message.loading.title
        }
        if (config.message.loading.text) {
          text.text = config.message.loading.text
        }
      }
      Vue.swal({
        title: text.title,
        text: text.text,
        onOpen: () => { Vue.swal.showLoading() }
      })
    }
  }
  static getDefaultTextMessages (type) {
    var text = {
      title: '',
      text: ''
    }
    switch (type) {
      case 'success':
        text = {
          title: 'Tudo certo!',
          text: 'Concluído com sucesso.'
        }
        break;
      case 'error':
        text = {
          title: 'Oops... Algo deu errado!',
          text: 'Tente novamente ou contacte o suporte.'
        }
        break;
    }
    return text
  }
  // static messageAsk (askConfig, callback) {
  //   Vue.swal({
  //     title: askConfig.title || 'Deseja realmente fazer isso?',
  //     text: askConfig.text || 'Esta ação pode ser irreversível!',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Sim! Sei o que estou fazendo',
  //     cancelButtonText: 'Cancelar'
  //   })
  //   .then((response) => {
  //     if (response.value) {
  //       if (callback) {
  //         callback()
  //       }
  //     }
  //   })
  // }
  static getBaseUrl () {
    return window.env.api.url + '/api/'
  }
}

