<template>
  <v-card v-if="sale">
    <v-card-text>
      <v-row>
        <v-col>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large :color="sale.data.category.color">
                {{ sale.data.category.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><strong>Descrição</strong></v-list-item-title>
              <v-list-item-subtitle>
                {{ sale.data.category.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large color="blue"> list_alt </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><strong>Data de Contratação</strong></v-list-item-title>
              <v-list-item-subtitle>
                {{ sale.data.opened_at }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large color="green"> monetization_on </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><strong>Valor Total</strong> </v-list-item-title>
              <v-list-item-subtitle>
                {{ sale.data.amount | money }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large color="yellow"> pie_chart </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><strong>Situação</strong></v-list-item-title>
              <v-list-item-subtitle>
                {{ sale.data.situation }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SaleDashboard",
  props: ["sale"],
};
</script>

<style></style>
