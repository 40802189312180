<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi :apiUrl="'portal/graduations/commission'">
              <template slot-scope="commission">
                <PersonsTable :commission="commission"></PersonsTable>
              </template>
            </LoaderApi>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
import PersonsTable from "@/pages/Persons/PersonsTable";
export default {
  name: "persons",
  components: {
    LoaderApi,
    PersonsTable,
  },
};
</script>

<style>
.persons-table__status--paid {
  color: green;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: green;
  width: 300px;
  height: 100px;
  font-weight: bold;
}
.persons-table__status--waiting {
  color: orange;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: orange;
  width: 30px;
  font-weight: bold;
}
.persons-table__status--expired {
  color: red;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: red;
  width: 30px;
  font-weight: bold;
}
</style>
