<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi
              :apiUrl="'portal/service-desk/ticket/' + $route.params.id"
              v-on:onLoad="setTicket"
            >
              <TicketDashboard :ticket="ticket" :refresh="refresh"></TicketDashboard>
              <br />
              <v-row v-if="ticket && ticket.stage === 1" justify="center">
                <v-col cols="12" sm="12" md="6" lg="6" xl="4">
                  <v-card>
                    <v-card-title class="headline">
                      <span>Adicione uma anotação ou comentário</span>
                    </v-card-title>
                    <v-card-text style="padding: 0 10px">
                      <v-textarea
                        v-model="newEvent"
                        :loading="newEventLoading"
                        :disabled="newEventLoading"
                        filled
                        auto-grow
                        rows="2"
                        row-height="20"
                        hide-details
                        label="Digite sua interação..."
                      ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!newEvent"
                        :loading="newEventLoading"
                        outlined
                        color="green"
                        @click="addEvent()"
                      >
                        <v-icon>add</v-icon>
                        <span style="padding-left: 5px;">Adicionar</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <br />
              <h2 class="center-text text-center">Últimas interações</h2>
              <br />
              <v-timeline clipped :dense="$isMobile" v-if="ticket">
                <v-slide-x-transition group>
                  <v-timeline-item
                    small
                    class="mb-4"
                    v-for="event in timeline"
                    :key="event.index"
                  >
                    <template v-slot:opposite>
                      <span class="headline font-weight-bold">{{ event.created_at }}</span>
                    </template>
                    <v-card class="elevation-15">
                      <v-card-title class="headline">
                        <span v-if="event.from_me">Eu:</span>
                        <span v-else>Empresa:</span>
                      </v-card-title>
                      <v-card-text>
                        <p>
                          <span v-html="event.description"></span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-slide-x-transition>
                <v-timeline-item>
                  <template v-slot:opposite>
                    <span class="headline font-weight-bold">{{ ticket.created_at }}</span>
                  </template>
                  <v-card class="elevation-15">
                    <v-card-title class="headline">
                      <span v-if="ticket.create_from_me">Eu:</span>
                      <span v-else>Empresa:</span>
                    </v-card-title>
                    <v-card-text>
                      <p>
                        <span v-html="ticket.description"></span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </LoaderApi>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
import TicketDashboard from "@/pages/Ticket/TicketDashboard";
export default {
  name: "Ticket",
  data: () => ({
    refresh: null,
    nonce: 100000,
    ticket: null,
    newEvent: null,
    newEventLoading: false,
  }),
  computed: {
    timeline() {
      return this.ticket.events.slice().reverse();
    },
  },
  methods: {
    setTicket: function(data) {
      this.ticket = data.data;
      this.refresh = data.refresh;
    },
    addEvent: function() {
      this.newEventLoading = true;
      this.$Api.post({
        url: "portal/service-desk/ticket/event",
        data: {
          ticket_id: this.$route.params.id,
          description: this.newEvent,
        },
        success: (response) => {
          this.addEventToView(response.data);
        },
        error: () => {
          this.newEventLoading = false;
        },
        config: {
          message: {
            loading: {
              show: false,
            },
            error: true,
          },
        },
      });
    },
    addEventToView: function(event) {
      event["index"] = this.nonce++;
      this.ticket.events.push(event);
      this.newEvent = null;
      this.newEventLoading = false;
    },
  },
  components: {
    LoaderApi,
    TicketDashboard,
  },
};
</script>
