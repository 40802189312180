<template>
  <div>
    <v-app-bar flat dense color="#C5C5C5" class="my-5">
      <v-toolbar-title> {{ finances.length }} parcela(s) encontrada(s) </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-simple-table class="elevation-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Descrição</th>
            <th class="text-center">FATURA(S)</th>
            <th class="text-center">STATUS</th>
            <th class="text-center">DATA DE REFERÊNCIA</th>
            <th class="text-center">DATA DE VENCIMENTO</th>
            <th class="text-center">VALOR</th>
            <th class="text-center">PAGO EM</th>
            <th class="text-center" colspan="2">OPÇÕES</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, iKey) in finances" :key="iKey" class="text-center">
            <td>{{ item.description }}</td>
            <td>
              <span v-if="item.automatically">
                {{ item.invoice_date }}
              </span>
              <span v-else>
                {{ item.number_this }} / {{ item.number_total }}
              </span>
            </td>
            <td>
              <span class="finances-table__status--paid" v-if="item.status == 'paid'">
                Pago
              </span>
              <span
                class="finances-table__status--waiting"
                v-else-if="item.status == 'waiting'"
              >
                Aguardando
              </span>
              <span class="finances-table__status--expired" v-else>
                Atrasada
              </span>
            </td>
            <td>{{ item.date_competence }}</td>
            <td>{{ item.date_validity }}</td>
            <td>
              <strong>{{ item.amount | money }}</strong>
            </td>
            <td>
              <span v-if="item.date_receive == null">
                <v-btn color="grey" text>Não Pago</v-btn>
              </span>
              <span v-else>{{ item.date_receive }}</span>
            </td>
            <td>
              <div v-if="item.status == 'paid'">
                <v-btn
                  color="primary"
                  outlined
                  small
                  :disabled="true"
                >Ver Comprovante</v-btn>
              </div>
              <div v-else-if="item.status === 'expired' && ((!item.payment || !item.payment.date_deadline_payment) || item.payment.date_deadline_payment_expired)">
                <v-btn
                  color="primary"
                  outlined
                  small
                  :loading="financeLoading.indexOf(item.id) > -1"
                  @click="questionCreatePayment(item.id)"
                >Emitir 2ª Via</v-btn>
              </div>
              <div v-else-if="!item.payment || !item.payment.url">
                <v-btn
                  color="primary"
                  outlined
                  small
                  :loading="financeLoading.indexOf(item.id) > -1"
                  @click="questionCreatePayment(item.id)"
                >Emitir boleto</v-btn>
              </div>
              <div v-else>
                <v-btn
                  color="primary"
                  outlined
                  small
                  link
                  :href="item.payment.url"
                  target="_blank"
                >Abrir boleto</v-btn>
              </div>
            </td>
            <td>
              <div v-if="item.invoice">
                <v-btn
                  color="primary"
                  outlined
                  small
                  link
                  :href="item.invoice.url"
                  target="_blank"
                >nota fiscal</v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "FinancesTable",
  data: () => ({
    financeLoading: []
  }),
  methods: {
    questionCreatePayment: function(financeId) {
      this.$swal({
        title: "Deseja realmente gerar um novo boleto?",
        text: "Ao confirmar um novo boleto será gerado!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim! Gerar novo boleto",
        cancelButtonText: "Cancelar",
      }).then((response) => {
        if (response.value) {
          this.createPayment(financeId);
        }
      });
    },
    createPayment: function(financeId) {
      this.touggleFinanceLoading(financeId)
      this.$Api.post({
        url: "portal/finances/finance/" + financeId + "/payment",
        data: {finance_id: financeId},
        success: (response) => {
          this.openCheckoutInNewTab(response.data)
          this.touggleFinanceLoading(financeId)
          this.refresh()
        },
        error: () => {
          this.errorMessage()
          this.touggleFinanceLoading(financeId)
        },
        config: {
          message: {
            loading: {
              show: true,
              title: 'Gerando seu boleto, por favor aguarde...',
              text: 'Geralmente não demora mais que alguns segundos.'
            },
            success: {
              show: true,
              title: 'Tudo certo, seu boleto foi criado.',
              text: 'Se ele não abrir, clique no botão "Ver boleto".'
            },
            error: {
              show: true,
              title: 'Ocorreu um erro ao criar seu boleto.',
              text: 'Tente mais uma vez, se não der certo entre em contato conosco.'
            }
          }
        }
      })
    },
    errorMessage: function() {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "Ocorreu um erro ao gerar o boleto",
      });
    },
    openCheckoutInNewTab: function (data) {
      if (data && data.data && data.data.url) {
        window.open(data.data.url, "_blank")
      }
    },
    touggleFinanceLoading: function (financeId) {
      var index = this.financeLoading.indexOf(financeId)
      if (index !== -1) {
        this.financeLoading.splice(index, 1);
      } else {
        this.financeLoading.push(financeId)
      }
    }
  },
  props: ["finances", "refresh"]
}
</script>
<style>
.finances-table__status--paid {
  color: green;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: green;
  width: 300px;
  height: 100px;
}
.finances-table__status--waiting {
  color: orange;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: orange;
  width: 30px;
}
.finances-table__status--expired {
  color: red;
  border-style: solid;
  border-width: 1px;
  padding: 5px;
  border-color: red;
  width: 30px;
}
</style>
