export class Storage {
  static set (key, data) {
    localStorage.setItem(key, this.crypt(data))
  }
  static get (key) {
    var data = localStorage.getItem(key)
    if (data) {
      return this.decrypt(data)
    }
    return null
  }
  static crypt (data) {
    return btoa(btoa(JSON.stringify(data)))
  }
  static decrypt (data) {
    return JSON.parse(atob(atob(data)))
  }
}
