import Vue from 'vue'
import App from '@/default/pages/App.vue'
import vuetify from '@/default/plugins/vuetify'
import router from '@/default/route/route'
import {i18n} from '@/default/lang/lang'
import store from '@/default/store/store'
import prototype from '@/default/prototypes/prototypes'
import filters from '@/default/filters/filters'
import VueSweetalert2 from 'vue-sweetalert2'
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective)

Vue.use(VueSweetalert2)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  store,
  prototype,
  filters,
  render: h => h(App)
}).$mount('#app')
