<template>
  <v-form v-model="isValid" class="register-address">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Informe sua turma</h2>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" class="register-address__col">
          <v-select v-model="form.grade_id" :items="gradeItems" label="Qual sua turma?" placeholder="Selecione sua turma" :disabled="isSaved" :loading="loading || loadingGrade" outlined block :rules="[rules.required]"></v-select>
        </v-col>
        <input v-model="form.person_id" type="hidden">
        <input v-model="form.graduation_id" type="hidden">
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterGrade',
    data: () => ({
      stageName: 'grade',
      gradeItems: [],
      loadingGrade: false,
      rules: {
        required: value => !!value || 'Campo obrigatório.'
      }
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.grade 
        },
        set (value) {
          this.$store.commit('register/formGrade', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      graduation () {
        return this.$store.state.register.form.graduation 
      },
      person () {
        return this.$store.state.register.form.person 
      }
    },
    methods: {
      getGrades: function () {
        this.loadingGrade = true
        this.$Auth.register('graduation/grade/' + this.form.graduation_id, {}, 
        response => {
          if (response && response.data) {
            this.gradeItems = response.data
          }
          this.loadingGrade = false
        })
      },
      setDataToStage: function () {
        var graduation = this.$Storage.get('register-0')
        var person = this.$Storage.get('register-2')
        this.form.person_id = person.id
        this.form.graduation_id =graduation.graduation_id
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(5)
      this.setDataToForm(5, 'grade')
      this.setDataToStage()
      this.getGrades()
    },
    props: ['valid'],
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-address h2 {
    padding-bottom: 10px;
  }
  .register-address__col {
    padding: 0 12px;
  }
  .register-terms__topics {
    padding: 20px;
  }
  .register-terms__checkbox {
    text-align: center !important;
    align-content: center !important;
  }
</style>

