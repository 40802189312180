<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <LoaderApi :apiUrl="'portal/graduations/commission-report'">
            <template slot-scope="report">
              <v-divider></v-divider>
              <div class="text-center my-5" v-if="report && report.data">
                <h1>Relatório de Caixa</h1>
                <p>{{ report.data.title }}</p>
              </div>

              <v-divider></v-divider>

              <div class="text-center mt-4" v-if="report && report.data">
                <span class="Report-text_relato">
                  Turma(s) e Número de Formandos
                </span>

                <div v-for="(titles, TKey) in report.data.grades" :key="TKey">
                  <span class="text-center">
                    {{ titles.name }} :
                    <strong>{{ titles.persons }} formandos</strong>
                  </span>
                </div>
                <v-divider></v-divider>
                <div class="text-center my-4">
                  <span class="Report-title_relato">
                    Número total de formandos participantes do projeto:

                    {{ report.data.grades[0].persons + report.data.grades[1].persons }}
                  </span>
                </div>
                <v-divider></v-divider>

                <div class="text-center mt-4">
                  <span class="Report-text_relato">
                    Detalhamento por turma
                  </span>
                </div>
              </div>
              <v-card-text>
                <v-simple-table class="elevation-3" v-if="report && report.data">
                  <template v-slot:default>
                    <tbody
                      class="text-center"
                      v-for="(item, iKey) in report.data.grades"
                      :key="iKey"
                    >
                      <tr>
                        <td rowspan="13">
                          <strong>{{ item.name }}</strong>
                        </td>
                      </tr>
                      <tr v-for="(itemp, pKey) in item.products" :key="pKey">
                        <td>
                          {{ itemp.name }}
                        </td>
                        <td>
                          <strong>{{ itemp.persons }} </strong> formandos
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-divider></v-divider>
              <div class="text-center mt-4">
                <span class="Report-text_relato">
                  Detalhamento Geral
                </span>
              </div>
              <v-card-text>
                <v-simple-table class="elevation-3" v-if="report && report.data">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(itemProd, ProdKey) in report.data.products"
                        :key="ProdKey"
                        class="text-center"
                      >
                        <td>
                          {{ itemProd.name }}
                        </td>
                        <td>
                          <strong> {{ itemProd.persons }} </strong> formandos
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-divider></v-divider>
              <div class="text-center mt-4">
                <span class="Report-text_relato">
                  Situação financeira
                </span>
              </div>

              <v-card-text>
                <v-simple-table
                  class="elevation-3"
                  v-if="report && report.data && report.data.situation"
                >
                  <template v-slot:default>
                    <tbody class="text-center">
                      <tr>
                        <td>
                          Boletos pagos até a presente data:
                        </td>
                        <td>
                          <strong class="Report-table__status--paid">
                            {{ report.data.situation.paid | money }}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Boletos vencidos até a presente data:
                        </td>
                        <td>
                          <strong class="Report-table__status--expired"
                            >{{ report.data.situation.expired | money }}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Boletos a serem pagos até o final do projeto:
                        </td>
                        <td>
                          <strong class="Report-table__status--waiting">
                            {{ report.data.situation.unpaid | money }}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Projeção total de arrecadação do projeto:
                        </td>
                        <td>
                          <strong> {{ report.data.situation.total | money }} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <div class="text-center my-10" v-if="report && report.data">
                <span class="Report-text_relato">
                  Relatório emitido em {{ report.data.date_time }}
                </span>
              </div>
              <v-divider></v-divider>
            </template>
          </LoaderApi>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";

export default {
  name: "graduation-report",
  data: () => ({
    disabled: null,
    category: {},

    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        name: "Eclair",
        calories: 262,
      },
      {
        name: "Cupcake",
        calories: 305,
      },
      {
        name: "Gingerbread",
        calories: 356,
      },
      {
        name: "Jelly bean",
        calories: 375,
      },
      {
        name: "Lollipop",
        calories: 392,
      },
    ],
  }),
  methods: {},

  computed: {
    config() {
      return this.$store.state.system.login;
    },
  },
  components: {
    LoaderApi,
  },
};
</script>

<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
.itemPag {
  color: green;
}

.Report-table__status--paid {
  color: green;
  padding: 5px;
  width: 300px;
  height: 100px;
  font-weight: bold;
}
.Report-table__status--waiting {
  color: orange;
  padding: 5px;
  width: 30px;
  font-weight: bold;
}
.Report-table__status--expired {
  color: red;
  padding: 5px;
  width: 30px;
  font-weight: bold;
}
.Report-text_relato {
  color: grey;
  padding: 5px;
  width: 30px;
  font-weight: bold;
  font-weight: center;
}
.Report-title_relato {
  color: #073f67;
  padding: 5px;
  width: 30px;
  font-weight: bold;
  font-weight: center;
}
</style>
