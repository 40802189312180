<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi apiUrl="portal/sales/sale">
              <template slot-scope="sales">
                <v-app-bar flat dense color="#C5C5C5" class="my-5" v-if="sales && sales.data">
                  <v-toolbar-title v-if="sales.data.length === 1">
                    <span>{{ sales.data.length }} Contratação</span>
                  </v-toolbar-title>
                  <v-toolbar-title v-else>
                    <span>{{ sales.data.length }} Contratações</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-app-bar>
                <div v-if="sales && sales.data">
                  <SalesTable :sales="sales.data"></SalesTable>
                </div>
              </template>
            </LoaderApi>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
import SalesTable from "@/pages/Sales/SalesTable";
export default {
  name: "sale",

  data: () => ({
    sale: null,
    form: {},
  }),
  methods: {
    showDetail: function(item) {
      this.sale = item;
      this.$router.push({ path: "/#/main/", name: "sale", params: { id: this.sale.id } });
    },
  },

  computed: {
    config() {
      return this.$store.state.system.login;
    },
  },
  components: {
    LoaderApi,
    SalesTable,
  },
};
</script>
<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
</style>
