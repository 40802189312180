import Vue from 'vue'

Vue.filter('money', function (value) {
  if (value) {
    if ((value.toString().split('.').length - 1) > 0) {
      value = value.toLocaleString('pt-BR')
      if (value.toString().split(',')[1].length === 1) {
        value = value + '0'
      }
      return 'R$' + ' ' + value
    } else {
      return 'R$' + ' ' + value.toLocaleString('pt-BR') + ',00'
    }
  } else if (value === 0) {
    return 'R$' + ' 0,00'
  } else {
    return value
  }
})

export default {}