<template>
  <v-form v-model="isValid" class="register-person">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Informe seus dados pessoais</h2>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" class="register-person__col">
          <v-text-field v-model="form.name" name="name" label="Qual seu nome?" placeholder="Digite seu nome aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required, rules.nameMin, rules.nameMax]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" class="register-person__col">
          <v-text-field v-model="form.cpf_cnpj" name="cpf" label="Qual seu CPF?" placeholder="Digite seu CPF aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required, rules.cpf]" v-mask="'###.###.###-##'"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-person__col">
          <v-text-field v-model="form.rg" name="rg" label="Qual seu RG?" placeholder="Digite seu RG aqui" :disabled="isSaved" :loading="loading" outlined block :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="register-person__col">
          <v-text-field v-model="form.birthday" name="birthday" label="Qual sua data de nascimento?" placeholder="Digite aqui" :disabled="isSaved" :loading="loading" outlined block type="date" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" class="register-person__col">
          <v-text-field v-model="form.email" name="email" label="Qual seu E-mail" placeholder="Digite seu E-mail aqui" :disabled="isSaved" :loading="loading" outlined block type="email" :rules="[rules.required, rules.email]"></v-text-field>
        </v-col>
        <v-col v-if="!form.id" cols="12" sm="12" md="6" lg="6" class="register-person__col">
          <v-text-field v-model="form.password" name="password" label="Crie uma senha" placeholder="Digite sua senha aqui" :loading="loading" outlined block :append-icon="showPassword ? 'visibility' : 'visibility_off'" :rules="[rules.required, rules.passwordMin, rules.passwordMax]" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
        </v-col>
        <v-col v-if="!form.id" cols="12" sm="12" md="6" lg="6" class="register-person__col">
          <v-text-field label="confirme sua senha" placeholder="Digite sua senha novamente" :loading="loading" outlined block :rules="[rules.required, rules.password(form.password)]" type="password"></v-text-field>
        </v-col>
        <input v-model="form.terms_id" type="hidden">
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import RegisterMixin from '@/default/pages/Register/Mixins/RegisterMixin'
  export default {
    name: 'RegisterPerson',
    data: () => ({
      stageName: 'person',
      showPassword: false,
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        nameMin: v => !!v && v.length >= 10 || 'Mínimo de 10 Caracteres',
        nameMax: v => !!v && v.length <= 70 || 'Máximo de 70 Caracteres',
        email: v => /.+@.+\..+/.test(v) || 'E-mail inválido',
        password: form => (value => value == form || 'A senha digitada não é igual'),
        passwordMin: v => v && v.length >= 8 || 'Mínimo de 8 Caractere',
        passwordMax: v => v && v.length <= 20 || 'Máximo de 20 Caractere',
        cpf: v => v && v.length == 14 || 'CPF inválido!'
      }
    }),
    computed: {
      isValid: {
        get () {
          return this.valid
        },
        set (valid) {
          this.$emit('valid', valid)
        }
      },
      form: {
        get () {
          return this.$store.state.register.form.person 
        },
        set (value) {
          this.$store.commit('register/formPerson', value)
        }
      },
      isSaved () {
        return this.form.id != null || this.form.id != undefined
      },
      terms () {
        return this.$store.state.register.form.terms 
      }
    },
    mounted: function () {
      this.checkIfPreviousStageIsComplete(2)
      this.setDataToForm(2, 'person')
      this.form.terms_id = this.terms.id
    },
    props: ['valid'],
    mixins: [RegisterMixin]
  }
</script>
<style scoped>
  .register-person h2 {
    padding-bottom: 10px;
  }
  .register-person__col {
    padding: 0 12px;
  }
  .register-terms__topics {
    padding: 20px;
  }
  .register-terms__checkbox {
    text-align: center !important;
    align-content: center !important;
  }
</style>

