import Vue from 'vue'
import axios from 'axios'

export class Auth {
  static get () {
    var auth = localStorage.getItem('e465008c-f08c-445d-8795-c1b1b847009f')
    if (auth) {
      var attributes = atob(auth)
      attributes = atob(attributes.substr(32, attributes.length))
      attributes = JSON.parse(attributes)
      if (attributes && attributes.data) {
        return attributes.data
      }
    }
    this.logout()
    return false
  }
  static check () {
    var auth = localStorage.getItem('e465008c-f08c-445d-8795-c1b1b847009f')
    if (auth) {
      return true
    }
    return false
  }
  static user () {
    var auth = this.get()
    if (auth.user) {
      return auth.user
    }
    return {}
  }
  static token () {
    var auth = this.get()
    if (auth.token) {
      return auth.token
    }
    return null
  }
  static logout (ask) {
    if (ask) {
      Vue.swal({
        title: 'Deseja realmente sair?',
        text: 'Esta ação pode ser irreversível!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim! Sei o que estou fazendo',
        cancelButtonText: 'Cancelar'
      })
      .then((response) => {
        if (response.value) {
          this.clearSession()
        }
      })
    } else {
      this.clearSession()
    }
  }
  static clearSession () {
    localStorage.clear()
    location.href = '/login'
    window.location.reload()
  }
  static authentication (credentials, callback) {
    if (credentials && credentials.username && credentials.password) {
      axios.post(this.getBaseUrl() + 'portal/auth/login', this.makeCredentialsToSend(credentials))
      .then(response => {
        this.saveAuthentication(response)
        this.callbackAuthentication(callback, 'success')
      }, () => {
        this.callbackAuthentication(callback, 'error')
      })
    }
  }
  static makeCredentialsToSend (credentials) {
    return {Bearer: btoa(btoa(JSON.stringify(credentials)))}
  }
  static saveAuthentication (response) {
    if (response && response.data && response.data.token) {
      localStorage.setItem('e465008c-f08c-445d-8795-c1b1b847009f', response.data.token)
    }
  }
  static callbackAuthentication (callback, status) {
    if (callback) {
      if (status === 'success') {
        callback({ status: status, password_change: this.user().password_change })
      } else {
        callback({ status: status })
      }
    }
  }
  static register (url, data, callback) {
    var headers = this.getApiHeaders(url)
    if (data) {
      axios.post(this.getBaseUrl() + 'portal/register/' + url, data, headers) // this.makeCredentialsToSend(data)
      .then(response => {
        this.callbackRegister(callback, response)
      })
      .catch(({ response }) => {
        this.callbackRegister(callback, response)
      })
    }
  }
  static getApiHeaders (url) {
    var headers = {
      headers: {'Content-Type': 'application/json'}
    }
    if (url == 'image-doc' || url == 'image-person-verso' || url == 'image-person-doc') {
      headers.headers['Content-Type'] = 'multipart/form-data'
    }
    return headers
  }
  static callbackRegister (callback, data) {
    if (callback) {
      callback(data)
    }
  }
  static getBaseUrl () {
    return window.env.api.url + '/api/'
  }
}
