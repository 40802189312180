import Vue from 'vue'
import {Auth} from '@/default/services/Auth'
import {Api} from '@/default/services/Api'
import {Storage} from '@/default/services/Storage'

Vue.prototype.$Auth = Auth
Vue.prototype.$Api = Api
Vue.prototype.$Storage = Storage
Vue.prototype.$isMobile = window.innerWidth < 600
Vue.prototype.$isMobile = window.innerWidth < 600

export default {}