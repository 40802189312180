import { Auth } from '@/default/services/Auth'

export default {
  namespaced: true,
  state: {
    active: false,
    loading: false,
    message: null,
    coupon: {}
  },
  mutations: {
    active (state, data) {
      state.active = data
    },
    loading (state, data) {
      state.loading = data
    },
    message (state, data) {
      state.message = data
    },
    coupon (state, data) {
      state.coupon = data
    }
  },
  actions: {
    setActive: ({ commit }, active) => {
      commit('active', active)
    },
    validateCouponSlug: ({ state, commit, dispatch }) => {
      commit('loading', true)
      commit('message', null)
      Auth.register('business/coupon', { slug: state.coupon.slug }, response => {
        dispatch('setCoupon', response)
        commit('message', response.data.message)
        commit('loading', false)
      })
    },
    setCoupon: ({ commit }, response) => {
      if (response.data && response.data.status === 'success' && response.data.data && response.data.data.id) {
        commit('coupon', response.data.data)
        commit('register/setBusinessCoupon', response.data.data.id, { root: true })
        // commit('active', false)
      }
    }
  }
}
