<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card style="background: #e0e0e0;">
          <v-card-text>
            <LoaderApi :apiUrl="'portal/sales/sale/' + $route.params.id">
              <template slot-scope="sale">
                <div v-if="sale && sale.data">
                  <SaleDashboard :sale="sale"></SaleDashboard>
                  <FinancesTable :finances="sale.data.finances" :refresh="sale.refresh">
                  </FinancesTable>
                </div>
              </template>
            </LoaderApi>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LoaderApi from "@/default/components/LoaderApi/LoaderApi";
import FinancesTable from "@/default/components/Finance/FinancesTable";
import SaleDashboard from "@/pages/Sale/SaleDashboard";

export default {
  name: "Sale",
  data: () => ({
    disabled: null,
    category: {},
  }),
  methods: {},

  computed: {
    config() {
      return this.$store.state.system.login;
    },
  },
  components: {
    LoaderApi,
    FinancesTable,
    SaleDashboard,
  },
};
</script>

<style>
.swal2-popup {
  font-family: "Open Sans", sans-serif;
}
.itemPag {
  color: green;
}
</style>
